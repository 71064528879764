import { Injectable } from '@angular/core';
import {Observer, Observable} from 'rxjs';
import {HttpHeaderResponse, HttpEventType} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ConferenceService} from '../conference.service';
import {NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import {User} from '../class/user';

const sUrlRegex = /(http|https)\:\/\/([a-zA-Z0-9\@\.\-\:]+)(\/|\?|\#)([.\S]*)?/gm;

export class ChatItem {
    get message() : string {
        return this.m_sMsg;
    }
    
    get timestamp() : number {
        return this.m_nTS;
    }
    
    get id() : string {
        return this.m_sID;
    }
    
    get user() : User {
        return this.m_oUser;
    }
    
    get userName() : string {
        if (!this.m_oUser) return "Uživateľ";
        
        return this.m_oUser.displayName;
    }
    
    get isLocal() : boolean {
        if (!this.m_oUser) return false;
        
        return this.m_oUser.isLocal;
    }
    
    constructor(private m_oUser : User, private m_sID : string, private m_sMsg : string, 
                private m_nTS : number = Date.now()) { 
                
        this.m_sMsg = this.m_sMsg.replace(sUrlRegex, p_sX => {
            return '<a target="_blank" class="link-info" href="'+p_sX+'">'+p_sX+'</a>';
        });
    }
    
}

export class FileItem {
    private m_oFile: File;
    private m_sDesc: string = "";
    private m_nUploadProgress : number = 0;
    private m_oUploading : boolean = false;
    private m_oUploaded : boolean = false;
    private m_nTime : number = Date.now();
    private m_nSize : number = 0;
    private m_sContentType : string = "";
    private m_oUploadUrls : any
    private m_sName : string;
    
    get name() : string {
        return this.m_sName;
    }
    
    get file() : File {
        return this.m_oFile;
    }
    
    get downloadUrl() : string {
        return this.m_oUploadUrls.get as string;
    }
    
    get uploadUrl() : string {
        return this.m_oUploadUrls.put as string;
    }
    
    get desc() : string {
        return this.m_sDesc;
    }
    
    set desc(p_sDesc : string) {
        this.m_sDesc = p_sDesc;
    }
    
    get progress() : number {
        return this.m_nUploadProgress;
    }
    
    set progress(p_nProgress : number) {
        this.m_nUploadProgress = p_nProgress;
    }
    
    get uploading() : boolean {
        return this.m_oUploading;
    }
    
    set uploading(p_bUpload : boolean) {
        this.m_oUploading = p_bUpload;
    }
    
    get uploaded() : boolean {
        return this.m_oUploaded;
    }
    
    set uploaded(p_bUpload : boolean) {
        this.m_oUploaded = p_bUpload;
    }
    
    get time() : number {
        return this.m_nTime;
    }
    
    get size() : number {
        return this.m_nSize;
    }
    
    constructor( p_oFEntry: NgxFileDropEntry) {
        
        if (p_oFEntry instanceof NgxFileDropEntry) {
            this.prepareToUpload(p_oFEntry);
        }
    }
    
    public setUrls(p_oUrls : any) {
        this.m_oUploadUrls = p_oUrls;
    }
    
    private setFileType(p_oFile : File)  {
        this.m_sContentType = p_oFile.type;
        this.m_nSize        = p_oFile.size;
        this.m_sName        = p_oFile.name;
    }
    
    private fileEntryToFile(p_oEntry: NgxFileDropEntry): Promise<File> {
        let oPromise = new Promise<File>((resolve, reject) => {
            if (p_oEntry.fileEntry.isFile) {
                const oFSEntry = p_oEntry.fileEntry as FileSystemFileEntry;
                
                oFSEntry.file((p_oFile : File) => {
                    this.m_oFile = p_oFile;
                    this.setFileType(p_oFile);
                    resolve(p_oFile);
                });
            }
        });
        
        return oPromise;
    }
    
    public async prepareToUpload(p_oFileE: NgxFileDropEntry) {
        this.m_oFile = await this.fileEntryToFile(p_oFileE);
    }
    
    public toMessage() {
        let sMsg = "Príloha: " + this.m_sDesc + "\n";
        // if (this.m_sDesc && this.m_sDesc.length > 0)
        //    sMsg += " : \n";
            
        sMsg += "url: " + this.downloadUrl;
        return sMsg;
    }
}


@Injectable({
  providedIn: 'root'
})
export class ChatService {
    private m_aFileItems: FileItem[] = [];
    private m_aChatItems: ChatItem[] = [];
    
    get messages(): ChatItem[] {
        return this.m_aChatItems;
    }
    
    get files(): FileItem[] {
        return this.m_aFileItems;
    }
    
    constructor(private m_oConference: ConferenceService) { }
    
    public sendMsg(p_sMsg:any) {
        if (!this.m_oConference.isConnected) return;
        if (!this.m_oConference.conference) return;
        
        this.m_oConference.conference.sendTextMessage(p_sMsg);
    };
    
    public receiveMsg(p_oMsg: ChatItem) {
        this.m_aChatItems.push(p_oMsg);
        
        if (this.m_aChatItems.length > environment.chat.maxShowItems)
            this.m_aChatItems.shift();
    }
    
    public uploadFile(p_oFile: FileItem) {
        this.m_aFileItems.push(p_oFile);
        
        return new Observable<any>((p_oObserver) => {
            
            return this.m_oConference.xmppUploadFile(p_oFile.file).subscribe(p_oUrls => {
                p_oFile.uploading = true;
                p_oFile.setUrls(p_oUrls);
                
                this.m_oConference.uploadFile(p_oFile.file, p_oFile.uploadUrl).subscribe( p_oHttp => {
                    if (p_oHttp.type == HttpEventType.UploadProgress ) {
                        p_oFile.progress = p_oHttp.loaded / p_oHttp.total;
                    } else if (p_oHttp instanceof HttpHeaderResponse) {
                        p_oFile.uploading = false;
                        
                        p_oFile.uploaded = p_oHttp.status === 201;
                        
                        if (p_oFile.uploaded)
                            p_oObserver.complete();
                    }
                }, p_oError => {
                   p_oObserver.error(p_oError);
                });
                
            }, p_oError => {
                p_oObserver.error(p_oError);
            });
            
            return () => {};
        });
    }
    
    public removeFile(p_oFile: FileItem) {
        let nIndex = this.m_aFileItems.indexOf(p_oFile);
        this.m_aFileItems.splice(nIndex, 1);
    }
    
    public clearFiles() {
        this.m_aFileItems.splice(0, this.m_aFileItems.length);
    }
    
    public clearMessages() {
        this.m_aChatItems.splice(0, this.m_aChatItems.length);
    }
}
