import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap/';
import { NgxFileDropEntry } from 'ngx-file-drop';
import {ChatService, FileItem} from '../../chat.service';
 
@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.css']
})
export class UploadDialogComponent implements OnInit {
    
    private m_oFiles: FileItem[] = [];
    
    get uploads(): FileItem[] {
        return this.m_oFiles;
    }
    
    get isEmpty() : boolean {
        return this.m_oFiles.length <= 0;
    }

    get activeModal(): NgbActiveModal {
        return this.m_oActiveModal;
    }
    
    constructor(private m_oChatSvc: ChatService, 
                private m_oActiveModal: NgbActiveModal) { }

    ngOnInit(): void { }

    public onDropFiles(UFiles: NgxFileDropEntry[]) {
        
        console.info('[MyJitsi] drop files', UFiles);
        
        for (const oDroppedFile of UFiles) {
            if (oDroppedFile.fileEntry.isFile) {
                let oUFI = new FileItem(oDroppedFile);
                this.m_oFiles.push(oUFI);
                console.info('[MyJitsi] drop file', oUFI);
            }
        }
    }
    
    public onDeleteItem(p_oFItem: FileItem) {
        let nIndex = this.m_oFiles.indexOf(p_oFItem);
        
        this.m_oFiles.splice(nIndex, 1);
    }
    
}
