import {Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {UserList} from '../class/user-list';
import {User} from '../class/user';
import {ConferenceService} from '../conference.service';

@Component({
  selector: '[vClassLeftUserList], vclass-left-user-list',
  templateUrl: './left-user-list.component.html',
  styleUrls: ['./left-user-list.component.css']
})
export class LeftUserListComponent implements OnInit {
    @Output("onHide")
    private m_oOnHide: EventEmitter<any> = new EventEmitter<any>();
    
    @Input("usersList")
    private m_oUserList: UserList;    
    
    get users() : string[] {
        if (this.m_oUserList)
            return this.m_oUserList.totalUsers;
        
        return [];
    }
    
    get hasList() : boolean {
        return this.m_oUserList ? true : false;
    }
    
    public userType(p_oUser : User): string {
        return p_oUser.isModerator ? "user-shield" : "user-graduate";
    }
    
    public videoType(p_oUser : User): string {
        return p_oUser.videoMuted ? "video-slash" : "video";
    }
    
    public audioType(p_oUser : User) : string {
        return p_oUser.audioMuted ? "microphone-alt-slash" : "microphone-alt";
    }
    
    constructor(private m_oJitsiSVC: ConferenceService) { }

    ngOnInit(): void { }

    public user(p_sUserID : string) : User {
        return this.m_oUserList.get(p_sUserID);
    }
    
    public onHide() {
        this.m_oOnHide.emit();
    }
    
    public isModerator(p_oUser : User) : boolean {
        if (!p_oUser) return false;
        
        return p_oUser.isModerator;
    }
    
    public mute(p_sPID: string) {
        this.m_oJitsiSVC.muteParticipant(p_sPID);
    }
    
    public muteElse(p_sPID : string) {
        this.m_oJitsiSVC.muteOtherElse(p_sPID);
    }
    
    public onMuteAll() {
        this.m_oJitsiSVC.muteOtherElse("");
    }
    
    public kickOut(p_sPID : string) {
        this.m_oJitsiSVC.kickOut(p_sPID);
    }
    
    public isLocal(p_oUser : User) : boolean {
        if (!p_oUser) return false;
        
        return p_oUser.isLocal;
    }
}
