import {Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {User} from '../class/user';


export enum EEventType {
    onMicEvent,
    onHandEvent,
    onTiledEvent,
    onSignOutEvent,
    onSharedScreenEvent,
    onUsersEvent,
    onChatEvent
}

@Component({
  selector: '[vClassBottomCtrlPanel],vclass-bottom-ctrl-panel',
  templateUrl: './bottom-ctrl-panel.component.html',
  styleUrls: ['./bottom-ctrl-panel.component.css']
})
export class BottomCtrlPanelComponent implements OnInit {
    @Output("eventsHandler")
    private m_oEventsHandler: EventEmitter<EEventType> = new EventEmitter<EEventType>();
    
    @Input("localUser")
    private m_oUser: User;
    
    @Input("isShowUsers")
    private m_nIsShowUsers: boolean = false;
    
    @Input("isShowTiled")
    private m_nIsShowTiled: boolean = false;
    
    @Input("isChatOn")
    private m_bIsChatOn: boolean = false;

    public get isSharedScreen() : boolean {
        if (this.m_oUser)
            return this.m_oUser.sharedScreen;
            
        return false;
    }

    public get isModerator() : boolean {
        if (this.m_oUser)
            return this.m_oUser.isModerator;
        
        return false;
    }
    
    public get micMuted() : boolean {
        if (this.m_oUser)
            return this.m_oUser.audioMuted;
        
        return false;
    }
    
    public get isVoted() : boolean {
        if (this.m_oUser)
            return this.m_oUser.voted;
            
        return false;
    }        
    
    public get isShowUsers() : boolean {
        return this.m_nIsShowUsers;
    }
    
    public get isShowTiled() : boolean {
        return this.m_nIsShowTiled;
    }

    public get isChatOn() : boolean {
        return this.m_bIsChatOn;
    }
    
    constructor() { }

    ngOnInit(): void {  }

    public onSignOut() {
        console.log("[myJitsi] btm ctrl panel signout");
        this.m_oEventsHandler.emit(EEventType.onSignOutEvent);
    }
    
    public onHand() {
        this.m_oEventsHandler.emit(EEventType.onHandEvent);
    }
    
    public onTiled() {
        this.m_oEventsHandler.emit(EEventType.onTiledEvent);
    }
    
    public onMic() {
        this.m_oEventsHandler.emit(EEventType.onMicEvent);
    }
    
    public onShareScreen() {
        this.m_oEventsHandler.emit(EEventType.onSharedScreenEvent);
    }
    
    public onUsers() {
        this.m_oEventsHandler.emit(EEventType.onUsersEvent);
    }
    
    public onChat() {
        this.m_oEventsHandler.emit(EEventType.onChatEvent)
    }
}
