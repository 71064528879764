import { Component, OnInit } from '@angular/core';
import {ChatService, FileItem} from '../chat.service';
import {ConferenceService} from '../../conference.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UploadDialogComponent} from './upload-dialog/upload-dialog.component';

@Component({
  selector: '[vClassFileManager], vclass-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.css']
})
export class FileManagerComponent implements OnInit {
    get files(): FileItem[] {
        return this.m_oChatSvc.files;
    }
    
    get empty() : boolean {
        return this.files && this.files.length <= 0;
    }

    constructor(private m_oChatSvc: ChatService, 
                private m_oConferecneSvc: ConferenceService, 
                private m_oModalSvc: NgbModal) { }

    ngOnInit(): void { }

    public openUpload() {
        const oModalRef = this.m_oModalSvc.open(UploadDialogComponent, {scrollable: true, size: 'lg'});
        let oModalInstance = oModalRef.componentInstance as UploadDialogComponent;
        oModalRef.result.then((p_oResult) => {
            //console.log('dialog', p_oResult);
            
            if (p_oResult === "upload") {
                this.uploadFiles(oModalInstance.uploads);
                // this.upload(oModalInstance.m_oFData);
            }
        });
    }
    
    private uploadFiles(p_aFiles: FileItem[]) {
        for (let oFile of p_aFiles) {
            this.m_oChatSvc.uploadFile(oFile).subscribe(p_oData => {
                console.log("[MyJitsi] upload data", p_oData);
                
            }, p_oError => {
                console.log("[MyJitsi] upload error", p_oError);
            }, () => {
                this.m_oChatSvc.sendMsg(oFile.toMessage());
            });
        }
    }
    
    public onDeleteItem(p_oFile: FileItem) {
        this.m_oChatSvc.removeFile(p_oFile);
    }
    
    public clearUploads() {
        this.m_oChatSvc.clearFiles();
    }
}
