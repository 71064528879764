import { Component, OnInit } from '@angular/core';
import { AlertsService, CToast } from '../../services/alerts.service';

@Component({
  selector: '[alertsContainer], alerts-container',
  templateUrl: './alerts-container.component.html',
  styleUrls: ['./alerts-container.component.css']
})
export class AlertsContainerComponent implements OnInit {
    public get toasts(): CToast[] {
        return this.m_oAlertSvc.toasts;
    }

    constructor(private m_oAlertSvc: AlertsService) { }

    ngOnInit(): void { }
    
    public remove(p_oToast: CToast) {
        this.m_oAlertSvc.remove(p_oToast);
    }

}
