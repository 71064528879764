import {Track} from "./track";
import {JitsiLocalUser} from "./jitsi-local-user";

export class User {
    
    private m_oTrack: Track = new Track();
    private m_bSharedScreen : boolean = false;
    private m_bIsDominantSpeaker : boolean = false;

    public get sharedScreen() : boolean {
        return this.m_bSharedScreen;
    }
    
    public set sharedScreen(p_nShared : boolean) {
        this.m_bSharedScreen = p_nShared;
    }

    public get id() : string {
        return this.m_oUser.getId();
    }
    
    public get jid() : string {
        return this.m_oUser.getJid();
    }
    
    public get displayName(): string {
        return this.m_oUser.getDisplayName();
    }
    
    public get track(): Track {
        return this.m_oTrack;
    }
    
    public get isLocal() : boolean {
        return this.m_oUser instanceof JitsiLocalUser;
    }
    
    public get isModerator() : boolean {
        return this.m_oUser.isModerator();
    }
    
    public get audioMuted() : boolean {
        if (this.m_oTrack && this.m_oTrack.audio) {
            //console.log("[myJitsi] user audiomuted", this.id, this.m_oTrack.audio.isMuted(), this.m_oTrack.audio);
            return this.m_oTrack.audio.isMuted();
        }
            
        return true;
    }
    
    public get voted() : boolean {
        if (this.m_oUser)
            return this.m_oUser.getProperty("vote");
            
        return false;
    }
    
    public set voted(p_bVote : boolean) {
        if (this.m_oUser)
            this.m_oUser.setProperty("vote", p_bVote);
    }
    
    public get videoMuted() : boolean {
        if (this.m_oTrack && this.m_oTrack.video)
            return this.m_oTrack.video.isMuted();
            
        return true;
    }
    
    public get isSpeaker() : boolean {
        return this.m_bIsDominantSpeaker;
    }
    
    public set isSpeaker(p_bEnable : boolean) {
        this.m_bIsDominantSpeaker = p_bEnable;
    }
    
    constructor(private m_oUser: any | JitsiLocalUser) { }
    
    public addTrack(p_oTrack) {
        let oTrack = this.m_oTrack.addTrack(p_oTrack);
        
        if (this.isLocal) {
            if (oTrack) {
                console.log("[myJitsi] replace track on room", oTrack,p_oTrack, p_oTrack.isScreenSharing());
                (this.m_oUser as JitsiLocalUser).replaceTrack(oTrack, p_oTrack)
                .then(p_oRet => {
                    console.info("[myJitsi] replace track on room dispose track", p_oRet);
                    oTrack.dispose();                    
                }, p_oError => {
                        console.error("[myJitsi][error] replace track on room", p_oError);
                });
            } else {
                (this.m_oUser as JitsiLocalUser).addTrack(p_oTrack);
                console.log("[myJitsi] add track to room", p_oTrack);
            }
        }
    }
    
    public removeTrack(p_oTrack) {
        let oTrack = this.m_oTrack.removeTrack(p_oTrack);
        
        if (this.isLocal && oTrack) {
            (this.m_oUser as JitsiLocalUser).removeTrack(oTrack);
            console.log("[myJitsi] remove track from room", p_oTrack);
        }
    }
    
    public setDisplayName(p_sName : string) {
        if (this.isLocal)
            (this.m_oUser as JitsiLocalUser).setDisplyName(p_sName);
    }
}
