import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import {FontAwesomeModule, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faUser, faUserGraduate, faUserShield, faMicrophoneAlt, 
        faMicrophoneAltSlash, faSignOutAlt, faVideo, faVideoSlash, faCrown, 
        faShieldAlt, faTv, faHandPaper, faVolumeUp, faUsers, faCircle, faTimes,
        faChevronLeft, faChevronRight, faEllipsisV, faEllipsisH, faSpinner, 
    faPaperPlane, faFile, faComments as fasComments, faUpload, faLink} 
        from '@fortawesome/free-solid-svg-icons';
import {faWindows} from '@fortawesome/free-brands-svg-icons';
import {faUser as fasUser, faTimesCircle, faCopy, faComments, faComment, faTrashAlt} 
        from '@fortawesome/free-regular-svg-icons';
        
import {facLogin} from './login-icon';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConferenceComponent } from './jitsi/conference/conference.component';
import { LoginComponent } from './login/login.component';
import { JRTrackComponent } from './jitsi/jrtrack/jrtrack.component';
import { JLTrackComponent } from './jitsi/jltrack/jltrack.component';
import { UserComponent } from './jitsi/user/user.component';
import { TiledUsersComponent } from './jitsi/tiled-users/tiled-users.component';
import { LeftPanelUsersComponent } from './jitsi/left-panel-users/left-panel-users.component';
import { BottomCtrlPanelComponent } from './jitsi/bottom-ctrl-panel/bottom-ctrl-panel.component';
import { LeftUserListComponent } from './jitsi/left-user-list/left-user-list.component';

import {AlertsModule} from './alerts/alerts.module';
import {AlertsService} from './alerts/services/alerts.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ChatBoxComponent } from './jitsi/chat-box/chat-box.component';
import { ChatComponent } from './jitsi/chat-box/chat/chat.component';
import { ChatItemComponent } from './jitsi/chat-box/chat/chat-item/chat-item.component';
import { FileManagerComponent } from './jitsi/chat-box/file-manager/file-manager.component';
import { FileItemComponent } from './jitsi/chat-box/file-manager/file-item/file-item.component';
import { UploadDialogComponent } from './jitsi/chat-box/file-manager/upload-dialog/upload-dialog.component';
import { FileSizePipe } from './jitsi/chat-box/file-manager/file-size.pipe';
import { ExtErrorToastComponent } from './ext-error-toast/ext-error-toast.component';

@NgModule({
    declarations: [
        AppComponent,
        ConferenceComponent,
        LoginComponent,
        JRTrackComponent,
        JLTrackComponent,
        UserComponent,
        TiledUsersComponent,
        LeftPanelUsersComponent,
        BottomCtrlPanelComponent,
        LeftUserListComponent,
        ChatBoxComponent,
        ChatComponent,
        ChatItemComponent,
        FileManagerComponent,
        FileItemComponent,
        UploadDialogComponent,
        FileSizePipe,
        ExtErrorToastComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        FontAwesomeModule,
        AlertsModule,
        AppRoutingModule,
        NgbModule,
        NgxFileDropModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { 
    constructor(private m_oFaLib: FaIconLibrary, 
                private m_oAlertSvc: AlertsService) {
        this.m_oAlertSvc.setColor('info', 'bg-dark text-white-50');
         
        this.m_oFaLib.addIcons(faSpinner);
        
        this.m_oFaLib.addIcons(faUser);
        this.m_oFaLib.addIcons(faUserShield);
        this.m_oFaLib.addIcons(faUserGraduate);
        this.m_oFaLib.addIcons(fasUser);
        
        this.m_oFaLib.addIcons(faMicrophoneAlt);
        this.m_oFaLib.addIcons(faMicrophoneAltSlash);
        this.m_oFaLib.addIcons(faVideo);
        this.m_oFaLib.addIcons(faVideoSlash);
        this.m_oFaLib.addIcons(faTv);
        this.m_oFaLib.addIcons(faHandPaper);
        this.m_oFaLib.addIcons(faUsers);
        this.m_oFaLib.addIcons(faVolumeUp);
        
        this.m_oFaLib.addIcons(faCrown);
        this.m_oFaLib.addIcons(faShieldAlt);
        this.m_oFaLib.addIcons(faWindows);
        this.m_oFaLib.addIcons(faSignOutAlt);
        
        this.m_oFaLib.addIcons(faCircle);
        this.m_oFaLib.addIcons(faTimes);
        this.m_oFaLib.addIcons(faChevronLeft);
        this.m_oFaLib.addIcons(faChevronRight);
        this.m_oFaLib.addIcons(faEllipsisV);
        this.m_oFaLib.addIcons(faEllipsisH);
        this.m_oFaLib.addIcons(faTimesCircle);
        
        this.m_oFaLib.addIcons(faCopy);
        this.m_oFaLib.addIcons(faFile);
        this.m_oFaLib.addIcons(faComments);
        this.m_oFaLib.addIcons(fasComments);
        this.m_oFaLib.addIcons(faComment);
        this.m_oFaLib.addIcons(faPaperPlane);
        this.m_oFaLib.addIcons(faUpload);
        this.m_oFaLib.addIcons(faLink);
        this.m_oFaLib.addIcons(faTrashAlt);
        
        // this.m_oFaLib.addIcons(facLogin);
    }
}