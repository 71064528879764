import { Injectable } from '@angular/core';
import {Observer, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {ConfigService} from '../../environments/config.service';
import {HttpClient, HttpHeaders, HttpEvent, HttpResponse} from '@angular/common/http';

import 'jquery';
import * as JitsiMeetJS from 'lib-jitsi-meet-orig/dist/umd/lib-jitsi-meet.min';
// import 'lib-jitsi-meet-orig/dist/umd/lib-jitsi-meet.e2ee-worker';
// 'lib-jitsi-meet/dist/lib-jitsi-meet';

@Injectable({
  providedIn: 'root'
})
export class ConferenceService {

    private m_oJitsiJS : any;
    private m_oConnection : any;
    private m_bConnected: boolean = false;
    private m_oConference: any = null;
    private m_sXmppDomain : string = null;
    
    public get jitsiJS():any {
        return this.m_oJitsiJS;
    }
    
    public get connection() : any {
        return this.m_oConnection;
    }
    
    public get isConnected() : boolean {
        return this.m_bConnected;
    }

    public get conference() : any {
        return this.m_oConference;
    }
    
    public get xmppDomain() : string {
        return this.m_sXmppDomain;
    }

    constructor(private m_oHttpClient: HttpClient, 
                private m_oCfgSvc: ConfigService) { 
        this.m_oJitsiJS = (window as any).JitsiMeetJS;
        this.m_oJitsiJS.init(environment.jitsi.initOptions);
        this.m_oJitsiJS.setLogLevel(this.m_oJitsiJS.logLevels.INFO);
    }
    
    public connect(p_sJID: string, p_sPasswd: string, p_sDomainID: string = ""): Observable<any> {
        let oConnOptions = Object.assign({},  environment.jitsi.connOptions);
        
        // if (p_sDomainID && p_sDomainID.length > 0) {
        oConnOptions.hosts.domain   = this.m_oCfgSvc.getXMPPAuthDomain(); // p_sDomainID; // + "." + environment.baseDomain;
        oConnOptions.hosts.muc      = this.m_oCfgSvc.getXMPPMuc(); // oConnOptions.hosts.muc + "." + p_sDomainID;
        oConnOptions.serviceUrl     = this.m_oCfgSvc.getXMPPServiceUrl();  // "wss://tssgroup.virtualnaucebna.sk/xmpp/ws";
        // }
        
        this.m_sXmppDomain = oConnOptions.hosts.domain;
        
        this.m_oConnection = new this.m_oJitsiJS.JitsiConnection(null, null, oConnOptions);
        let oConnection = this.m_oConnection;
        
        return new Observable<any>((p_oObserver)=>{
            const hFailed = (p_mErrType, p_mMsg, p_mCredential, p_mDetail) => {
                console.log("!---[xmpp] connection failed", p_mErrType, p_mMsg, p_mCredential, p_mDetail);
                let oError = {
                    Type : p_mErrType, 
                    Msg  : p_mMsg, 
                    Credential : p_mCredential, 
                    Detail : p_mDetail
                };
                p_oObserver.error(oError);
                this.m_oConnection.disconnect();
            };
            
            const hSuccess = () => {
                this.m_bConnected = true;
                p_oObserver.next(this);
                console.info("!---[xmpp] connection success");
            };
            
            const hDisconnect = () => {
                this.m_bConnected = false;
                this.m_oConference = null;
                console.info("!---[xmpp] connection disconnected");
            };
            
            oConnection.addEventListener(this.m_oJitsiJS.events.connection.CONNECTION_FAILED, hFailed);
            oConnection.addEventListener(this.m_oJitsiJS.events.connection.CONNECTION_ESTABLISHED, hSuccess);
            oConnection.addEventListener(this.m_oJitsiJS.events.connection.CONNECTION_DISCONNECTED, hDisconnect);
            
            oConnection.connect({id: this.m_oCfgSvc.genXMPPLoginJID(p_sJID), password: p_sPasswd});
            
            return () => {                
                oConnection.removeEventListener(this.m_oJitsiJS.events.connection.CONNECTION_FAILED, hFailed);
                oConnection.removeEventListener(this.m_oJitsiJS.events.connection.CONNECTION_ESTABLISHED, hSuccess);
                oConnection.removeEventListener(this.m_oJitsiJS.events.connection.CONNECTION_DISCONNECTED, hDisconnect);
            };
        });
    }
    
    public disconect() {
        console.warn("disconnect");
        this.m_bConnected = false;
        this.m_oConnection.disconnect();
    }
    
    public createConference(p_sRoomName : string) : any {
        if (!this.m_oConference) {
            console.log("room name:", p_sRoomName);
            this.m_oConference = this.m_oConnection.initJitsiConference(
                                    p_sRoomName, environment.jitsi.confOptions);
        }
        
        return this.m_oConference;
    }
    
    public kickOut(p_sPID : string) {
        if (!this.m_oConference) return;
        
        this.m_oConference.kickParticipant(p_sPID);
    }
    
    public muteParticipant(p_sPID : string) {
        if (!this.m_oConference) return;
        
        this.m_oConference.muteParticipant(p_sPID, "audio");
    }
    
    public muteOtherElse(p_sPID : string) {
        if (!this.m_oConference) return;
        
        for (let oJUser of this.m_oConference.getParticipants()) {
            if (oJUser.getId() !== p_sPID)
                this.m_oConference.muteParticipant(oJUser.getId(), 'audio');
        }
    }
    
    private get stropheConnection() : any {
        if (!this.m_oConnection) return null;
        if (!this.m_oConnection.xmpp) return null;
        if (!this.m_oConnection.xmpp.connection) return null;
        if (!this.m_oConnection.xmpp.connection._stropheConn) return null;
        
        return this.m_oConnection.xmpp.connection._stropheConn;
    }
    
    public uploadFile(p_oFile: File, p_sPutUrl: string): Observable<HttpEvent<Object>> {
        let oHedaers: HttpHeaders = new HttpHeaders({
                'Content-Type': p_oFile.type /*,
                'Content-Length': p_oFile.size.toString()*/
            });
            
        return this.m_oHttpClient.put(p_sPutUrl, p_oFile, {
            reportProgress: true,
            observe: "events",
            headers: oHedaers
        });
    }
    
    public xmppUploadFile(p_oFile: File): null | Observable<any> {
        if (!this.stropheConnection) return;
        
        return new Observable<any>((p_oObserver) => {
            
            this.stropheConnection.httpUpload.getUrls(p_oFile, (p_oData:any) => {
                p_oObserver.next(p_oData);
            }, (p_mError) => {
                p_oObserver.error(p_mError);
            });
            
            return () => {};
        });
    }
}
