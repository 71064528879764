import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbToastModule} from '@ng-bootstrap/ng-bootstrap';
import { AlertsContainerComponent } from './components/alerts-container/alerts-container.component';


@NgModule({
    declarations: [ 
        AlertsContainerComponent
    ],
    imports: [
        CommonModule,
        NgbToastModule
    ],
    exports: [
        AlertsContainerComponent
    ]
})
export class AlertsModule { }
