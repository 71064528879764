import {Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';

@Component({
  selector: '[jitsiAVTrack],jitsi-av-track,app-jltrack',
  templateUrl: './jltrack.component.html',
  styleUrls: ['./jltrack.component.css']
})
export class JLTrackComponent implements OnInit, OnDestroy, AfterViewInit {
    private m_oVideoCss = {'d-block' : true, 'd-none' : false};
    private m_oVideo : any;
    private m_oAudio : any;
    
    private m_bVideoAttached: boolean = false;
    private m_bAudioAttached: boolean = false;    
    
    @Input("onlyVideo")
    private m_bOnlyVideo : boolean = false;
    
    get onlyVideo() : boolean {
        return this.m_bOnlyVideo;
    }
    
    get videoMuted() : boolean {
        if (this.m_oVideo)
            return this.m_oVideo.isMuted();
        
        return true;
    }
    
    get videoCss() : {} {
        this.m_oVideoCss['d-block'] = !this.videoMuted;
        this.m_oVideoCss['d-none'] = this.videoMuted;
        return this.m_oVideoCss;
    }
    
    @Input("video")
    private set video(p_oTrack : any) {
        if (!p_oTrack) return;
        
        this.detachVideo();
        this.m_oVideo = p_oTrack;
        
        console.log("[myJitsi] jltrack set video", this.m_oVideo, this.m_oVideoElem);
        this.attachVideo();
    }
    
    @Input("audio")
    private set audio(p_oTrack : any) {
        if (!p_oTrack && this.m_bOnlyVideo) return;
        
        this.detachAudio();
        this.m_oAudio = p_oTrack;
        
        console.log("[myJitsi] jltrack set audio", this.m_oAudio, this.m_oAudioElem);
        this.attachAudio();
    }
    
    @ViewChild("vtrack")
    private m_oVideoElem: ElementRef<HTMLVideoElement>;
    @ViewChild("atrack")
    private m_oAudioElem: ElementRef<HTMLAudioElement>;

    constructor() { }

    ngOnInit(): void { }
    
    ngOnDestroy() {
        this.detachVideo();
        this.detachAudio();
    }
    
    ngAfterViewInit() {
        console.log("[myJitsi] jltrack afterViewInit");
        this.attachVideo();
        this.attachAudio();
    }
    
    private attachVideo() {
        console.log("[myJitsi] jltrack before attach video", this.m_oVideo, this.m_oVideoElem);
        if (this.m_oVideo && this.m_oVideoElem && 
            this.m_oVideoElem.nativeElement) {
            console.log("[myJitsi] jltrack attach video ", this.m_oVideo, this.m_oVideoElem);
            this.m_oVideo.attach(this.m_oVideoElem.nativeElement);
            this.m_bVideoAttached = true;
        }
    }
    private attachAudio() {
        console.log("[myJitsi] jltrack before attach audio", this.m_oAudio, this.m_oAudioElem);
        if (this.m_oAudio && this.m_oAudioElem && 
            this.m_oAudioElem.nativeElement) {
            console.log("[myJitsi] jltrack attach audio ", this.m_oAudio, this.m_oAudioElem);
            this.m_oAudio.attach(this.m_oAudioElem.nativeElement);
            this.m_bAudioAttached = true;
        }
    }
    
    private detachAudio() {
        console.log("[myJitsi] before dettach Audio", this.m_oAudio, this.m_oAudioElem);
        if (this.m_bAudioAttached && this.m_oAudio) {
            this.m_oAudio.detach(this.m_oAudioElem.nativeElement);
            console.log("[myJitsi] dettach Audio", this.m_oAudio, this.m_oAudioElem);
        }
    }
    
    private detachVideo() {
        console.log("[myJitsi] before dettach Video", this.m_oVideo, this.m_oVideoElem);
        if (this.m_bVideoAttached && this.m_oVideo) {
            this.m_oVideo.detach(this.m_oVideoElem.nativeElement);
            console.log("[myJitsi] dettach Video", this.m_oVideo, this.m_oVideoElem);
        }
    }
}
