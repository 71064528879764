import {Component, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-ext-error-toast',
  templateUrl: './ext-error-toast.component.html',
  styleUrls: ['./ext-error-toast.component.css']
})
export class ExtErrorToastComponent implements OnInit {
    
    constructor(private m_oTpl: TemplateRef<any>) { }

    ngOnInit(): void { }
}
