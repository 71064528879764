import {Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { User } from '../class/user';
import { KeyValueList } from '../class/user-list';
import {ConferenceService} from '../conference.service';

@Component({
  selector: '[jitsiUser],jitsi-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
    @Output("onSelect")
    private m_oOnSelect: EventEmitter<User> = new EventEmitter<User>();
    
    private m_oUserType: string = 'user-graduate';
    private m_oAudioType: string = 'microphone-alt-slash';
    private m_oVideoType: string = 'video-slash';
    
    @Input("showControls")
    private m_bShowControls : boolean = true;
    
    @Input("user")
    public m_oUser : User; 
    
    @Input("onlyVideo")
    public m_bOnlyVideo : boolean = false;
    
    public get showControls() : boolean {
        return this.m_bShowControls;
    }
    
    public get onlyVideo() : boolean {
        return this.m_bOnlyVideo;
    }
    
    public get video() : any {
        if (this.hasTracks)
            return this.m_oUser.track.video;
            
        return null;
    }
    
    public get displayName() : string {
        if (this.m_oUser) {
            
            return (this.m_oUser.isLocal ? "(Vy) " : "") 
                  + this.m_oUser.displayName;
        }
        return "";
    }
    
    public get audio() : any {
        if (this.hasTracks)
            return this.m_oUser.track.audio;
            
        return null;
    }
    
    public get hasTracks() : boolean {
        //console.log("[myJitsi] user component hasTracks", this.m_oUser);
        if (!this.m_oUser)
            return false;
            
        return !this.m_oUser.track.isEmpty;
    }
    
    public get userType(): string {
        if (this.m_oUser)
            this.m_oUserType = this.m_oUser.isModerator ? "user-shield" : "user-graduate";
            
        return this.m_oUserType;
    }
    
    public get isModerator() : boolean {
        if (this.m_oUser)
            return this.m_oUser.isModerator;
            
        return false;
    }
    
    public get audioType(): string {
        if (this.m_oUser) {
            this.m_oAudioType = this.m_oUser.audioMuted ? "microphone-alt-slash" : "microphone-alt";
            //console.log("[myJitsi] user component audiomuted", this.m_oUser.id, this.m_oUser.audioMuted, this.m_oAudioType);
        }
        
        return this.m_oAudioType;
    }
     
    public get videoType(): string {
        if (this.m_oUser)
            this.m_oVideoType = this.m_oUser.videoMuted? "video-slash" : "video";
        
        return this.m_oVideoType;
    }
    
    public get isVoted() : boolean {
        if (this.m_oUser)
            return this.m_oUser.voted;
            
        return false;
    }
    
    public get isSpeaker() : boolean {
        if (this.m_oUser)
            return this.m_oUser.isSpeaker;
            
        return false;
    }
    
    public get moderatorCtrls() : boolean {
        if (this.m_oJitsiSvC.conference && this.m_oUser && !this.m_oUser.isLocal)
            return this.m_oJitsiSvC.conference.isModerator();
            
        return false;
    }
    
    constructor(private m_oJitsiSvC: ConferenceService) { }

    ngOnInit(): void { }

    @HostListener("click")
    public onClick() {
        console.log("[myJitsi] user component onClick");
        this.m_oOnSelect.emit(this.m_oUser);
    }
    
    public kickOut() {
        this.m_oJitsiSvC.kickOut(this.m_oUser.id);
       // if (this.m_oJitsiSvC.conference)
       //     this.m_oJitsiSvC.conference.kickParticipant(this.m_oUser.id);
    }
    
    public mute() {
        this.m_oJitsiSvC.muteParticipant(this.m_oUser.id);
        //console.log("[myJitsi] user mute ", this.m_oUser.track.video);
        //if (!this.m_oUser.audioMuted && this.m_oJitsiSvC.conference)
        //    this.m_oJitsiSvC.conference.muteParticipant(this.m_oUser.id, "audio");
    }
    
    public muteElse() {
        this.m_oJitsiSvC.muteOtherElse(this.m_oUser.id);
//        if (this.m_oJitsiSvC.conference) {
//            for (let oJUser of this.m_oJitsiSvC.conference.getParticipants()) {
//                if (oJUser.getId() !== this.m_oUser.id)
//                    this.m_oJitsiSvC.conference.muteParticipant(oJUser.getId(), 'audio');
//            }
//        }       
    }
}