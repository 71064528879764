import {Component, OnInit, OnDestroy } from '@angular/core';
import { ConferenceService } from '../conference.service';
import {User} from "../class/user";
import {JitsiLocalUser} from "../class/jitsi-local-user";
import {UserList} from "../class/user-list";
import {Router, ActivatedRoute} from "@angular/router";
import {EEventType} from '../bottom-ctrl-panel/bottom-ctrl-panel.component';
import {AlertsService, CToast} from '../../alerts/services/alerts.service';
import {ChatService, ChatItem} from '../chat-box/chat.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-conference',
  templateUrl: './conference.component.html',
  styleUrls: ['./conference.component.css']
})
export class ConferenceComponent implements OnInit, OnDestroy {
    public m_bShowTiled : boolean = true;
    public m_bShowUsers : boolean = false;
    public m_oLocalTrack = { video : null, audio: null };
    public m_oRemoteTracks: any[] = [];
    public m_oAllTracks : any[] = [];
    public m_oUsers: UserList = new UserList();
    private m_nIsJoined: boolean = false;
    public m_bShowChat : boolean = false;

    // inittialization blok
    constructor(private m_oJitsiSVC: ConferenceService, 
                private m_oRouter: Router,
                private m_oRoute: ActivatedRoute,
                private m_oAlertSvc: AlertsService,
                private m_oChatSvc: ChatService) { 
        this.m_oUsers.addEvent(p_oUser => this.onSelectUser(p_oUser));
        this.m_oAlertSvc.clearAlerts();
        //this.m_oJitsiSVC.connect("pedro@jitsi.dca.gps.local", 'pedro');    
    }

    ngOnInit(): void {
        if (this.m_oJitsiSVC.isConnected) {
            this.m_oChatSvc.clearMessages();
            let sRoomName = this.m_oRoute.snapshot.paramMap.get("room_id");
            if (!sRoomName) {
                this.m_oAlertSvc.showDanger(new CToast("Nepodarilo sa pripojiť do učebne. Skontrolujte či máte spránvny URL link a akciu zopakujte, prípdne kontaktujte správcu učebne.", {
                        header: "Problém s pripojením do učebne", delay: 0
                    }));
                    
                this.logOut();
                return;
            }
            
            
            let oEvents = this.m_oJitsiSVC.jitsiJS.events;
            
            this.m_oJitsiSVC.connection.addEventListener(
                oEvents.connectionQuality.LOCAL_STATS_UPDATED, 
                p_oStat => this.onLocalStatsUpdated(p_oStat));
            this.m_oJitsiSVC.connection.addEventListener(
                oEvents.connectionQuality.REMOTE_STATS_UPDATED, 
                (p_sID, p_oStat) => this.onRemoteStatsUpdated(p_sID, p_oStat));
            
            console.info("[myJitsi] isDeviceAvailable ", 
                this.m_oJitsiSVC.jitsiJS.mediaDevices.isDeviceListAvailable());
            console.info("[myJitsi] getAudioOutputDevice", 
                this.m_oJitsiSVC.jitsiJS.mediaDevices.getAudioOutputDevice());
            
            this.m_oJitsiSVC.jitsiJS.mediaDevices.addEventListener(
                oEvents.mediaDevices.DEVICE_LIST_CHANGED, 
                p_oDevices => this.onDeviceListChg(p_oDevices));
                
            this.m_oJitsiSVC.jitsiJS.mediaDevices.addEventListener(
                oEvents.mediaDevices.PERMISSION_PROMPT_IS_SHOWN, 
                p_oPromptShown => this.onPermissionPromtIsShown(p_oPromptShown));
            
            this.m_oJitsiSVC.jitsiJS.mediaDevices.enumerateDevices(function(p_oMI){
                console.info("[myJitsi] enumerateDevices ", p_oMI);
            });
            console.info("[myJitsi] joining", this.m_oJitsiSVC.connection);
            
            this.prepareRoom(sRoomName);
        }
    }
    // destroy block
    ngOnDestroy() {
        this.logOut(false);
    }
    
    private createConference(p_sRoomName : string) {
        let oEvents = this.m_oJitsiSVC.jitsiJS.events;
        //let oConference = this.m_oJitsiSVC.createConference("testpersist");
        let oConference = this.m_oJitsiSVC.createConference(p_sRoomName);
        //console.log("[myJitsi] conference mute policy", oConference.getStartMutedPolicy());
        //oConference.setStartMutedPolicy({audio: true, video: false});
        //console.log("[myJitsi] conference mute policy", oConference.getStartMutedPolicy());
        oConference.on(oEvents.conference.TRACK_ADDED, 
            p_oTrack => this.onRemoteTrackAdd(p_oTrack));
        oConference.on(oEvents.conference.TRACK_REMOVED, 
            p_oTrack => this.onRemoteTrackRemove(p_oTrack));
        oConference.on(oEvents.conference.TRACK_MUTE_CHANGED,
             p_oTrack => this.onRemoteTrackMuteChg(p_oTrack));
        oConference.on(oEvents.conference.TRACK_AUDIO_LEVEL_CHANGED, 
            (p_sUserID, p_nAudioLevel) => 
            this.onRemoteTrackAudioLevelChg(p_sUserID, p_nAudioLevel));
        // -- user event
        oConference.on(oEvents.conference.USER_JOINED, 
            (p_oUserID, p_oUser) => this.onUserJoined(p_oUserID, p_oUser));
        oConference.on(oEvents.conference.USER_LEFT, 
            p_oUserID => this.onUserLeft(p_oUserID));
        oConference.on(oEvents.conference.USER_ROLE_CHANGED, 
            (p_sUserID, p_sRole) => this.onUserRoleChg(p_sUserID, p_sRole));
        oConference.on(oEvents.conference.USER_STATUS_CHANGED, 
            (p_sUserID, p_sState) => this.onUserStateChg(p_sUserID, p_sState));
        oConference.on(oEvents.conference.KICKED, 
            (p_oUser, p_sReason) => this.onKicked(p_oUser, p_sReason));
        oConference.on(oEvents.conference.PARTICIPANT_KICKED, 
            (p_oActorUser, p_oKickedUser, p_sReason) => 
            this.onUserKicked(p_oActorUser,p_oKickedUser, p_sReason));
        // --
        oConference.on(oEvents.conference.DISPLAY_NAME_CHANGED, 
            (p_oUserID, p_sDName) => this.onDisplayNameChg(p_oUserID, p_sDName));
        oConference.on(oEvents.conference.PHONE_NUMBER_CHANGED, 
            () => this.onPhoneNumberChg());
        //-- conference
        oConference.on(oEvents.conference.CONFERENCE_JOINED,
            () => this.onConferenceJoined());
        oConference.on(oEvents.conference.CONFERENCE_LEFT, 
            () => this.onConferenceLeft());
        oConference.on(oEvents.conference.CONFERENCE_UNIQUE_ID_SET, 
            (p_sMeetingID) => this.onConferenceUniqueIdSet(p_sMeetingID));
        oConference.on(oEvents.conference.CONFERENCE_FAILED, 
            (p_oError) => this.onConferenceFailed(p_oError));
        oConference.on(oEvents.conference.CONFERENCE_ERROR, 
            (p_oError) => this.onConferenceError(p_oError));
        //-- others
        oConference.on(oEvents.conference.DOMINANT_SPEAKER_CHANGED, 
            (p_sUserID) => this.onDominantSpeakerChanged(p_sUserID));
        oConference.on(oEvents.conference.SUBJECT_CHANGED, 
            (p_sSubject) => this.onSubjectChg(p_sSubject));
        oConference.on(oEvents.conference.LAST_N_ENDPOINTS_CHANGED, 
            (p_aEndPoints, p_aEndPoints1) => 
            this.onLastNEndPointsChanged(p_aEndPoints, p_aEndPoints1));
        oConference.on(oEvents.conference.DTMF_SUPPORT_CHANGED, 
            (p_bChg) => this.onDtmfSupportChg(p_bChg));
        oConference.on(oEvents.conference.START_MUTED_POLICY_CHANGED, 
            (p_oMPolicy) => this.onStartMutedPolicyChg(p_oMPolicy));
        oConference.on(oEvents.conference.STARTED_MUTED, 
            () => this.onStartMuted());
        oConference.on(oEvents.conference.BEFORE_STATISTICS_DISPOSED, 
            () => this.onBeforeStatisticsDisposed());
        oConference.on(oEvents.conference.AUTH_STATUS_CHANGED,
            (p_bIsAuthEnabled, p_sAuthIdentity) => 
            this.onAuthStatusChg(p_bIsAuthEnabled, p_sAuthIdentity));
        oConference.on(oEvents.conference.ENDPOINT_MESSAGE_RECEIVED, 
            (p_oUser, p_oData) => this.onEndpointMessageReceived(p_oUser, p_oData));
        oConference.on(oEvents.conference.TALK_WHILE_MUTED, 
            () => this.onTalkWhileMuted());
        oConference.on(oEvents.conference.NO_AUDIO_INPUT, 
            () => this.onNoAudioInput());
        oConference.on(oEvents.conference.AUDIO_INPUT_STATE_CHANGE, 
            () => this.onAudioInputStateChg());
        oConference.on(oEvents.conference.NOISY_MIC, () => this.onNoisyMic());
        oConference.on(oEvents.conference.PARTICIPANT_PROPERTY_CHANGED, 
            (p_oUser, p_sProperty, p_sOldVal, p_sNewVal) => 
            this.onParticipantPropertyChg(p_oUser, p_sProperty, p_sOldVal, 
                                          p_sNewVal));
        // -- meesage receive
        oConference.on(oEvents.conference.MESSAGE_RECEIVED, 
            (p_sMsgID: string, p_sMsg: string, p_nTS: number) => 
             this.onMessageReceive(p_sMsgID, p_sMsg, p_nTS));

        if (oConference) {
            let oLocalUser = new User(new JitsiLocalUser(this.m_oJitsiSVC.conference));
            // oLocalUser.setDisplayName(this.m_oJitsiSVC.connection.xmpp.getConnection().jid);
            this.m_oUsers.add(oLocalUser);
            this.getVCard(oLocalUser);

            oConference.join();
            /*this.m_oJitsiSVC.jitsiJS.createLocalTracks({ devices: [ 'audio', 'video' ] })
                .then(p_oTracks => this.onLocalTracks(p_oTracks))
                .catch(error => {
                    throw error;
                });*/
        }
    }
    
    private prepareRoom(p_sRoom : string) {
        
        let oIQ = (window as any).$iq({type: 'get', to: environment.tssfocus}).c('video-class-room', {
            xmlns: 'urn:xmpp:videoclassroom:0', 
            'rid': p_sRoom + "@" + this.m_oJitsiSVC.xmppDomain
            });
        this.m_oJitsiSVC.connection.xmpp.getConnection().sendIQ2(oIQ, 15000)
            .then((p_eRes : Element) => {
                console.log("[myJitsi] prepare room", p_eRes);
                if (p_eRes && p_eRes.childElementCount == 1) {
                    let oVCRResult = p_eRes.firstElementChild;
                    
                    if (oVCRResult.tagName == "video-class-room") {
                        let sRJID = oVCRResult.getAttribute("jid");
                        
                        if (sRJID.length > 0) {
                            let sRName = (window as any).Strophe.getNodeFromJid(sRJID);
                            let sDomain= (window as any).Strophe.getDomainFromJid(sRJID);
                            environment.jitsi.connOptions.hosts.muc = sDomain;
                            this.createConference(sRName);
                        }
                    }
                }
            }, (p_oError : Element) => {
                console.error("[myJitsi] prepare room failed", p_oError);
                    let oError = p_oError.firstElementChild;
                    
                    if (oError) {
                        let sType = oError.getAttribute("type");
                        let oErrorType = oError.firstElementChild;
                        
                        if (oErrorType) {
                            switch (oErrorType.tagName) {
                                
                                case 'not-allowed':
                                    this.m_oAlertSvc.showDanger(
                                        new CToast("Nepodarilo sa pripojiť do učebne. Prístup odmietnuty.", {
                                        header: "Problém s pripojením do učebne", delay: 0
                                    }));
                                    break;
                                case 'item-not-found':
                                    this.m_oAlertSvc.showDanger(
                                        new CToast("Nepodarilo sa pripojiť do učebne. Učebňa neexistuje.", {
                                        header: "Problém s pripojením do učebne", delay: 0
                                    }));
                                    break;
                                case 'internal-server-error':
                                default:
                                    this.m_oAlertSvc.showDanger(
                                        new CToast("Interná chyba servera.", {
                                        header: "Problém s pripojením do učebne", delay: 0
                                    }));
                                    break;                                
                            }
                        }
                    }
            });
    }
    
    private getVCard(p_oUser: User) {
        let oIQ = (window as any).$iq({type:'get'}).c('vCard', {xmlns:'vcard-temp'});
        this.m_oJitsiSVC.conference.xmpp.getConnection().sendIQ2(oIQ, 2000)
            .then((p_oReq : Element) => {
                console.log("[myJitsi] get vCard", p_oReq);
                
                if (p_oReq && p_oReq.childElementCount == 1) {
                    let oVCard = p_oReq.firstElementChild;
                    if (oVCard.tagName !== "vCard") return;
                    
                    let oFN = oVCard.querySelector("FN");
                    // console.log("[myJitsi] get vCard FN", oVCard, oFN);
                    if (oFN && p_oUser)
                        p_oUser.setDisplayName(oFN.textContent);
                }
            }, p_oError => {
                console.log("[myJitsi] get vCard error", p_oError);
            });
    }
    
    // -- events block
    
    // -- mediaDevices
    private onPermissionPromtIsShown(p_oPrompt) {
        console.log("[myJitsi] onPermissionPromtIsShown", p_oPrompt);
    }
        
    private onDeviceListChg(p_oDevices) {
        console.log("[myJitsi] onDeviceListChg", p_oDevices);
    }
        
    // -- 
    private muteAudio() {
        if (!this.m_oUsers) return;
        if (!this.m_oUsers.localUser) return;
        if (!this.m_oUsers.localUser.track) return;
        if (!this.m_oUsers.localUser.track.audio) return;
        
        if (this.m_oUsers.localUser.audioMuted)
            this.m_oUsers.localUser.track.audio.unmute();
        else
            this.m_oUsers.localUser.track.audio.mute();
    }
    
    private onSelectUser(p_oUser : User) {
        if (p_oUser) {
            this.m_bShowTiled = false;
            this.m_oJitsiSVC.conference.selectParticipant(p_oUser.id);
        }
            
        console.log("[myJitsi] onSelectUser", p_oUser);
    }
    
    private logOut(p_bRedirect = true) {
        if (this.m_oJitsiSVC.isConnected) {
            if (this.m_oJitsiSVC.conference) {
                this.m_oJitsiSVC.conference.leave().then(p_mOk => {
                    this.m_oJitsiSVC.disconect();
                    this.m_oUsers.localUser.track.dispose();
                    
                    if (p_bRedirect)
                        this.m_oRouter.navigate(['login'], { 
                            queryParams: {returnUrl: this.m_oRouter.url} 
                        });
                }, p_mError => {
                    this.m_oJitsiSVC.disconect();                
                    this.m_oUsers.localUser.track.dispose();
                    
                    if (p_bRedirect)
                        this.m_oRouter.navigate(['login'], { 
                            queryParams: {returnUrl: this.m_oRouter.url} 
                        });
                });
            } else {
                this.m_oJitsiSVC.disconect();
                //this.m_oRouter.navigate(['login'], {queryParams: {returnUrl: this.m_oRouter.url}});
            }
        } else if (p_bRedirect) {
            console.log("router", this.m_oRouter, this.m_oRouter.url);
            this.m_oRouter.navigate(['login'], {
                queryParams: {returnUrl: this.m_oRouter.url}
            });
        }
    }
    
    public onBottomPanel(p_eEvent: EEventType) {
        console.log("[myJitsi] conference btm ctr handelr", p_eEvent);
        switch (p_eEvent) {
            case EEventType.onMicEvent:
                console.log("[myJtisi] audio mute");
                this.muteAudio();
                
                break;
                
            case EEventType.onHandEvent:
                this.m_oUsers.localUser.voted = !this.m_oUsers.localUser.voted;
                break;
                
            case EEventType.onSharedScreenEvent:
                console.log("[myJtisi] shareScreen");
                if (this.m_oUsers.localUser.sharedScreen) {
                    this.m_oJitsiSVC.jitsiJS.createLocalTracks({                        
                        devices: [ 'video' ] 
                    }).then(p_oTracks => {
                        this.m_oUsers.localUser.sharedScreen = false;
                        this.onLocalTracks(p_oTracks);
                    }).catch(error => {
                        throw error;
                    });                    
                } else {
                    this.m_oJitsiSVC.jitsiJS.createLocalTracks({
                        firePermissionPromptIsShownEvent: true, 
                        devices: [ 'desktop' ] 
                    }).then(p_oTracks => {
                        this.m_oUsers.localUser.sharedScreen = true;
                        this.onLocalTracks(p_oTracks);
                    }).catch(error => {
                        throw error;
                    });                    
                }
                break;
                
            case EEventType.onTiledEvent:
                this.m_bShowTiled = !this.m_bShowTiled;
                break;
                
            case EEventType.onUsersEvent:
                this.m_bShowUsers = !this.m_bShowUsers;
                break;
                
            case EEventType.onSignOutEvent:
                console.log("[myJtisi] audio logOut");
                this.logOut()
                
                break;
                
            case EEventType.onChatEvent:
                this.m_bShowChat = !this.m_bShowChat;
                break;
        }
    }
    
    public hideUsers() {
        this.m_bShowUsers = false;
    }
    
    // --
    private onLocalStatsUpdated(p_oStat: any) {
        console.log("[myJitsi] onLocalStatsUpdated", p_oStat);
    }
    
    private onRemoteStatsUpdated(p_sID : string, p_oStat : any) {
        console.log("[myJitsi] onRemoteStatsUpdated", p_sID, p_oStat);
    }
    
    // -- local tracks event
    private onLocalTracks(p_oTracks) {
        let oLocalTracks = p_oTracks as any[];
        
        this.m_oLocalTrack =  { video : null, audio: null };
        let oUser = this.m_oUsers.localUser;
        if (!oUser) return;
        
        for (let nIndex = 0; nIndex < oLocalTracks.length; nIndex++) {
            
            if (oLocalTracks[nIndex].getType() == 'video' && oUser) {
                oLocalTracks[nIndex].addEventListener(
                    this.m_oJitsiSVC.jitsiJS.events.track.LOCAL_TRACK_STOPPED, 
                    p_oTrack => {
                        if (oUser.sharedScreen) 
                            this.onBottomPanel(EEventType.onSharedScreenEvent);
                        else
                            oUser.removeTrack(oLocalTracks[nIndex]);
                    });
                
                oUser.addTrack(oLocalTracks[nIndex]);
            //    this.m_oLocalTrack.video = oLocalTracks[nIndex];
            } else if (oLocalTracks[nIndex].getType() == 'audio' && oUser) {
                // this.m_oLocalTrack.audio = oLocalTracks[nIndex];
                oUser.addTrack(oLocalTracks[nIndex]);
            }
            
            console.log("[myJitsi] local track", nIndex, oLocalTracks[nIndex]);
            
            if (this.m_nIsJoined) {
                //this.m_oJitsiSVC.conference.addTrack(oLocalTracks[nIndex]);
                //console.log("[myJitsi] room add on track return");
            }
        }
        this.m_oAllTracks.push(this.m_oLocalTrack);
    }
    // -- remote track events
    private onRemoteTrackAdd(p_oTrack) {
        if (p_oTrack.isLocal()) return;
        
        console.log("[myJitsi] remote track", p_oTrack);
        
        let oUser = this.m_oUsers.get(p_oTrack.getParticipantId());

        console.log("[myJitsi] remote track travers usr", oUser);
        if (oUser)
            oUser.addTrack(p_oTrack);
    }
    
    private onRemoteTrackRemove(p_oTrack) {
        let oUser = this.m_oUsers.get(p_oTrack.getParticipantId());
        
        if (oUser)
            oUser.removeTrack(p_oTrack);
            
        console.log("[myJitsi] remote track Remove", p_oTrack);
    }
    
    private onRemoteTrackMuteChg(p_oTrack) {
        let oUser = this.m_oUsers.get(p_oTrack.getParticipantId());
        console.log("[myJitsi] remote track Mute Chg", 
                    p_oTrack.getParticipantId(), p_oTrack.isMuted(), oUser);
    }
    
    private onRemoteTrackAudioLevelChg(p_sUserID, p_nLevel) {
        //console.log("[myJitsi] remote track Audio Level Chg", p_sUserID, p_nLevel);
    }
    // -- user events
    private onUserJoined(p_sUserID, p_oUser) {
        let oUser = this.m_oUsers.add(new User(p_oUser));
        if (oUser) 
            this.m_oAlertSvc.showInfo(
                new CToast(oUser.displayName + " vstúpil do učebne", 
                    {
                        header: "Nový účastník"
                    }));
        
        console.log("[myJitsi] User Joined", p_sUserID, p_oUser, this.m_oUsers);
    }
    
    private onUserLeft(p_sUserID) {
        let oUser = this.m_oUsers.remove(p_sUserID);
        if (oUser)
            this.m_oAlertSvc.showInfo(
                new CToast(oUser.displayName + " opustil učebnu", 
                {
                    header: "Účastník opustil učebnu"
                }));
        
        console.log("[myJitsi] User Left", p_sUserID);
    }
    
    private onUserRoleChg(p_sUserID : string, p_sRole : string) {
        console.log("[myJitsi] User Role Chg", p_sUserID, p_sRole);
    }
    
    private onUserStateChg(p_sUserID : string, p_sState : string) {
        console.log("[myJitsi] User State Chg", p_sUserID, p_sState);
    }
    
    private onKicked(p_oUser : any, p_sReason : string) {
        console.log("[myJitsi] Is kicked", p_oUser, p_sReason);
        
        let oUser = this.m_oUsers.get(p_oUser.getId());
        if (oUser)
            this.m_oAlertSvc.showDanger(
                new CToast(oUser.displayName + " Vás vyhodil z učebne",
                {delay: 0, header: "Vyhodenie z učebne"}));
    }
    
    private onUserKicked(p_oActorUser : any, p_oKickedUser : any, 
                         p_sReason : string) {
        console.log("[myJitsi] User kicked", p_oActorUser, p_oKickedUser, p_sReason);
        
        let sMessage = "";
        let oUser = this.m_oUsers.get(p_oActorUser.getId());
        
        if (oUser)
            sMessage += oUser.displayName;            
            
        oUser = this.m_oUsers.get(p_oKickedUser.getId());        
        if (oUser) {
            sMessage += " vyhodil z učebné " + oUser.displayName;
            this.m_oAlertSvc.showInfo(
                new CToast(sMessage, {header: "Vyhodený účastník"}));
        }
    }
    
    //
    private onDisplayNameChg(p_sUserID, p_sDisplayName) {
        console.log("[myJitsi] Display Name Chg", p_sUserID, p_sDisplayName);
    }
    
    private onPhoneNumberChg() {
        console.log("[myJitsi] Phone Number Chg", 
                    this.m_oJitsiSVC.conference.getPhoneNumber(), 
                    this.m_oJitsiSVC.conference.getPhonePin());
    }
    
    // -- conference events
    private onConferenceJoined() {
        console.log("[myJitsi] OnConference joined", this.m_oJitsiSVC.conference);
        this.m_nIsJoined = true;
        let oLocalUser = this.m_oUsers.localUser;
        // new User(new JitsiLocalUser(this.m_oJitsiSVC.conference));
        // oLocalUser.setDisplayName(this.m_oJitsiSVC.connection.xmpp.getConnection().jid);
        //this.m_oUsers.add(oLocalUser);
        //this.getVCard(oLocalUser);
        
        this.m_oJitsiSVC.conference.setSenderVideoConstraint(360);
        this.m_oJitsiSVC.conference.setReceiverVideoConstraint(360);
        
        if (oLocalUser.isModerator)
            this.m_oJitsiSVC.conference.setStartMutedPolicy({audio: true, video: false});
        
            
        this.m_oJitsiSVC.jitsiJS.createLocalTracks({ devices: [ 'audio', 'video' ] })
        .then(p_oTracks => this.onLocalTracks(p_oTracks))
        .catch(error => {
            console.error("[myJitsi][error]", error);
            //throw error;
        });
    }
    
    private onConferenceLeft() {
        console.log("[myJitsi] onConferenceLeft");
    }
    
    private onConferenceUniqueIdSet(p_sMeetingID : string) {
        console.log("[myJitsi] onConferenceUniqueIdSet", p_sMeetingID);
    }
    
    private onConferenceFailed(p_oError: any) {
        console.error("[myJitsi] onConferenceFailed", p_oError);
        this.errorHandler(p_oError);
    }
    
    private onConferenceError(p_oError : any) {
        console.error("[myJitsi] onConferenceError", p_oError);
        this.errorHandler(p_oError);
       /* switch (p_oError) {
            
        } */
    }
    // -- others events
    private onDominantSpeakerChanged(p_sUserID) {
        this.m_oUsers.dominantSpeakerID = p_sUserID;
        console.log("[myJitsi] onDominantSpeakerChanged", p_sUserID);
    }
    
    private onSubjectChg(p_sSubject : string) {
        console.log("[myJitsi] onSubjectChg", p_sSubject);
    }
    
    private onLastNEndPointsChanged(p_aEndPoints, p_aEndPoints1) {
        console.log("[myJitsi] onLastNEndPointsChanged", p_aEndPoints, p_aEndPoints1);
    }
    
    private onDtmfSupportChg(p_bChg : boolean) {
        console.log("[myJitsi] onDtmfSupportChg", p_bChg);
    }
    
    private onStartMutedPolicyChg(p_oMPolicy: any) {
        console.log("[myJitsi] onStartMutedPolicyChg", p_oMPolicy);
        //if (p_oMPolicy.audio)
        //    this.m_oUsers.localUser.track.audio.mute();
    }
    
    private onStartMuted() {
        console.log("[myJitsi] onStartMuted");
    }
    
    private onBeforeStatisticsDisposed() {
        console.log("[myJitsi] onBeforeStatisticsDisposed");
    }
    
    private onAuthStatusChg(p_bIsAuthEnabled: boolean, p_sAuthIdentity : string) {
        console.log("[myJitsi] onAuthStatusChg", p_bIsAuthEnabled, p_sAuthIdentity);
    }
    
    private onEndpointMessageReceived(p_oUser, p_oData) {
        //console.log("[myJitsi] onEndpointMessageReceived", p_oUser, p_oData);
    }
    
    private onTalkWhileMuted() {
        console.log("[myJitsi] onTalkWhileMuted");
    }
    
    private onNoAudioInput() {
        console.log("[myJitsi] onNoAudioInput");
    }
    
    private onAudioInputStateChg() {
        console.log("[myJitsi] onAudioInputStateChg");
    }
    
    private onNoisyMic() {
        console.log("[myJitsi] onNoisyMic");
    }
    
    private onParticipantPropertyChg(p_oUser : any, p_sProperty : string, 
                                    p_sOldVal : string, p_sNewVal : string) {
        console.log("[myJitsi] onParticipantPropertyChg", p_oUser, p_sProperty, 
                    p_sOldVal, p_sNewVal);
    }
    
    private onMessageReceive(p_sID : string, p_sMsg : string, p_nTS : number) {
        let oUser = this.m_oUsers.get(p_sID);
        this.m_oChatSvc.receiveMsg(new ChatItem(oUser, p_sID, p_sMsg, p_nTS));
        
        if (oUser && !this.m_bShowChat)
            this.m_oAlertSvc.showInfo(
                new CToast(p_sMsg, 
                {
                    header: "Chat: Nová správa od " + oUser.displayName
                }));
    }

    private errorHandler(p_sError) {
        let oError = this.m_oJitsiSVC.jitsiJS.errors.conference;
        
        console.log("available error", oError);
        
        if (oError) {
            switch (p_sError) {
                case oError.MEMBERS_ONLY_ERROR:
                    this.m_oAlertSvc.showDanger(
                        new CToast('Pripojenie do miestnosti nebolo úspešné, nie'
                                  +' ste členom miestnosti. Skuste sa pripojiť '
                                  +'znovu, alebo kontaktujte správcu miestnosti.', 
                        {
                            delay: 0,
                            header: "Problém s pripojením do miestnosti "
                        }));
                    return;
                    
                case oError.NOT_ALLOWED_ERROR:
                    this.m_oAlertSvc.showDanger(
                        new CToast('Pripojenie do miestnosti nebolo úspešné. '
                                  +'Skuste sa pripojiť znovu, alebo '
                                  +'kontaktujte správcu miestnosti.', 
                        {
                            delay: 0,
                            header: "Neznama chyba"
                        }));
                    return;
                    
                case oError.AUTHENTICATION_REQUIRED:
                    this.m_oAlertSvc.showDanger(
                        new CToast('Pre pripojenie do miestnosti sa vyžaduje '
                                  +'prihlasenie. Kontaktujte správcu miestnosti.',
                            {
                                delay: 0,
                                header: "Požadovaná dodatočná autentifikacia"
                            }));
                    return;
                    
                case oError.CHAT_ERROR:
                    this.m_oAlertSvc.showDanger(
                        new CToast('Skuste sa pripojiť znovu, alebo kontaktujte'
                                  +' správcu miestnosti.',
                            {
                                delay: 0,
                                header: "Chyba chatu"
                            }));
                    return;
                    
                case oError.CONFERENCE_ACCESS_DENIED:
                    this.m_oAlertSvc.showDanger(
                        new CToast('Pripojenie do miestnosti bolo zamietnute. '
                                  +'Skuste sa pripojiť znovu, alebo kontaktujte'
                                  +' správcu miestnosti.',
                            {
                                delay: 0,
                                header: "Pripojenie do miestnosti zlyhalo"
                            }));
                    return;
                    
                case oError.CONFERENCE_DESTROYED:
                    this.m_oAlertSvc.showDanger(
                        new CToast('Miestnost bola ukoncena. '
                                  +'Skuste sa pripojiť znovu, alebo kontaktujte'
                                  +' správcu miestnosti.',
                            {
                                delay: 0,
                                header: "Ukončenie miestnosti"
                            }));
                    return;
                    
                case oError.CONFERENCE_MAX_USERS:
                    this.m_oAlertSvc.showDanger(
                        new CToast('Miestnosť dosiahla svoju kapacitu. '
                                  +'Skuste sa pripojiť znovu, alebo kontaktujte'
                                  +' správcu miestnosti.',
                            {
                                delay: 0,
                                header: "Miestnosť je obsadená"
                            }));
                    return;
                    
                case oError.CONFERENCE_RESTARTED:
                    this.m_oAlertSvc.showDanger(
                        new CToast('Došlo k reštartovaniu konferenčnej miestnosti. '
                                  +'Skuste sa pripojiť znovu, alebo kontaktujte'
                                  +' správcu miestnosti.',
                            {
                                delay: 0,
                                header: "Miestnosť je reštartovaná"
                            }));
                    return;
                    
                case oError.CONNECTION_ERROR:
                    this.m_oAlertSvc.showDanger(
                        new CToast('Skuste sa pripojiť znovu, alebo kontaktujte'
                                  +' správcu miestnosti.',
                            {
                                delay: 0,
                                header: "Chyba spoenia"
                            }));
                    return;
                    
                case oError.FOCUS_DISCONNECTED:
                    this.m_oAlertSvc.showDanger(
                        new CToast('Riadenie miestnosti sa odpojilo. '
                                  +'Skuste sa pripojiť znovu, alebo kontaktujte'
                                  +' správcu miestnosti.',
                            {
                                delay: 0,
                                header: "Chyba servera"
                            }));
                    return;
                    
                case oError.FOCUS_LEFT:
                    this.m_oAlertSvc.showDanger(
                        new CToast('Riadenie miestnosti sa odpojilo. '
                                  +'Skuste sa pripojiť znovu, alebo kontaktujte'
                                  +' správcu miestnosti.',
                            {
                                delay: 0,
                                header: "Chyba servera"
                            }));
                    return;
                    
                case oError.GRACEFUL_SHUTDOWN:
                    this.m_oAlertSvc.showDanger(
                        new CToast('Skuste sa pripojiť znovu, alebo kontaktujte'
                                  +' správcu miestnosti.',
                            {
                                delay: 0,
                                header: "Chyba servera - vypnutie"
                            }));
                    return;
                    
                case oError.INCOMPATIBLE_SERVER_VERSIONS:
                    this.m_oAlertSvc.showDanger(
                        new CToast('Skuste sa pripojiť znovu, alebo kontaktujte'
                                  +' správcu miestnosti.',
                            {
                                delay: 0,
                                header: "Chyba servera - nekompatibilna verzia"
                            }));
                    return;
                    
                case oError.PASSWORD_NOT_SUPPORTED:
                    this.m_oAlertSvc.showDanger(
                        new CToast('Skuste sa pripojiť znovu, alebo kontaktujte'
                                  +' správcu miestnosti.',
                            {
                                delay: 0,
                                header: "Heslo nie je podporovane"
                            }));
                    return;
                    
                case oError.PASSWORD_REQUIRED:
                    this.m_oAlertSvc.showDanger(
                        new CToast('Skuste sa pripojiť znovu, alebo kontaktujte'
                                  +' správcu miestnosti.',
                            {
                                delay: 0,
                                header: "Pažaduje sa heslo"
                            }));
                    return;
                    
                case oError.SETTINGS_ERROR:
                    this.m_oAlertSvc.showDanger(
                        new CToast('Skuste sa pripojiť znovu, alebo kontaktujte'
                                  +' správcu miestnosti.',
                            {
                                delay: 0,
                                header: "Chyba nastavení"
                            }));
                    return;
                    
                case oError.VIDEOBRIDGE_NOT_AVAILABLE:
                    this.m_oAlertSvc.showDanger(
                        new CToast('Pripojenie na video most sa nepodarilo. '
                                  +'Skuste sa pripojiť znovu, alebo kontaktujte'
                                  +' správcu miestnosti.',
                            {
                                delay: 0,
                                header: "Video most nedostupny"
                            }));
                    return;
            }
        }
    }
}
