import { Component, OnInit, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: '[vClassChatBox], vclass-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.css']
})
export class ChatBoxComponent implements OnInit {
    @Output("onClose")
    private m_oOnClose: EventEmitter<any> = new EventEmitter<any>();
    
    constructor() { }

    ngOnInit(): void { }

    public onClose() {
        console.log("[MyJitsi] onClose");
        this.m_oOnClose.emit();
    }
}
