import {Component, OnInit, OnChanges, SimpleChange, Input, HostListener, ElementRef } from '@angular/core';
import { UserList, KeyValueList } from '../class/user-list';
import { User } from '../class/user';

@Component({
  selector: '[vClassTiledUsers], vclass-tiled-users',
  templateUrl: './tiled-users.component.html',
  styleUrls: ['./tiled-users.component.css']
})
export class TiledUsersComponent implements OnInit {
    private m_oCssClass: KeyValueList<boolean> = {};
    private m_oStyle: KeyValueList<string> = {};
    private m_aCurrCss : string[] = [];
    
    @Input("usersList")
    private m_oUsersList: UserList;

    get hasList() : boolean {
        return this.m_oUsersList ? true : false;
    }
    
    get users() : string[] {
        if (this.hasList)
            return this.m_oUsersList.totalUsers;
        
        return [];
    }
    
    get tiledClass(): KeyValueList<boolean> {
        if (!this.hasList) return this.m_oCssClass;
        
        let nCount = this.m_oUsersList.totalUsers.length;
        let aNCss : string[] = [];
        
        if (nCount == 1) {
            aNCss.push('row-cols-1');
            this.calculateWidth();
        } else if (nCount >= 2 && nCount <= 4) {
            aNCss.push('row-cols-2');
            this.calculateWidth();
        } else if (nCount >= 5 && nCount <= 9)
            aNCss.push('row-cols-3');
        else if (nCount >= 10 && nCount <= 16)
            aNCss.push('row-cols-4');
        else if (nCount >= 17 && nCount <= 25)
            aNCss.push('row-cols-5');
        else if (nCount >= 26 && nCount <= 36)
            aNCss.push('row-cols-6');
            
        for (let sCssKey of this.m_aCurrCss)
            this.m_oCssClass[sCssKey] = false;
            
        for (let sCssKey of aNCss)
            this.m_oCssClass[sCssKey] = true;
            
        this.m_aCurrCss = aNCss;
        // console.log("[myJitsi] tiledUser css class", this.m_oCssClass);
        return this.m_oCssClass;
    }
    
    get tiledStyle(): KeyValueList<string> {
        return this.m_oStyle;
    }
    
    @HostListener("window:resize", ['$event'])
    onResize(p_oEvent: UIEvent) {
        this.calculateWidth();
    }
    
    constructor(private m_oMyElemRef: ElementRef<HTMLElement>) { }

    ngOnInit(): void {
        this.calculateWidth();
    }
     
    public onSelect(p_oUser: User) {
        this.m_oUsersList.selectUser(p_oUser.id);
    }
      
    public user(p_sUserID : string) : User {
        return this.m_oUsersList.get(p_sUserID);
    }
    
    public isSelected(p_sUserID : string) : boolean {
        if (!this.hasList || !this.m_oUsersList.selectedUser) return false;
        
        return this.m_oUsersList.selectedUser.id === p_sUserID;
    }
    
    private calculateWidth() {
        // console.log("[myJitsi] tiled component", this.m_oMyElemRef, 
        //            window.innerWidth, window.innerHeight,
        //            window.innerHeight / window.innerWidth);
        
        let nCount = 1;
        if (this.hasList)
            nCount = this.m_oUsersList.totalUsers.length;
        
        if ((nCount < 2 || nCount > 2) && this.m_oMyElemRef.nativeElement && 
            window.innerHeight / window.innerWidth < 0.5625) {
            //console.log("[myJitsi] tiled component size", window.innerWidth, 
            //            window.innerHeight);
            this.m_oMyElemRef.nativeElement
                .style.width = (window.innerHeight / 0.5625).toString() + "px";
        } else {
            this.m_oMyElemRef.nativeElement.style.width = "";
        }
    }

}