import { Component, OnInit } from '@angular/core';
import { ConferenceService } from '../jitsi/conference.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ConfigService} from '../../environments/config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    public m_sLogin  : string = "";
    public m_sPasswd : string = "";
    public m_bError : boolean = false;
    public m_bForgotPassword = false;
    public m_sLogo : string = 'tss';
    public m_sCustomCSS : string = 'base';
    public m_bHeader : boolean = true;
    public m_sHeaderContent : string = 'Virtuálna učebňa';
    private m_bProgress = false;
    private m_bDisabled = false;
    private m_bForgotSuccess = false;
    
    get progress() : boolean {
        return this.m_bProgress;
    }
    
    get disabled() : boolean {
        return this.m_bDisabled;
    }
    
    get disabledBtn() : boolean {
        return this.m_bDisabled || this.m_sLogin == "";
    }
    
    get forgotSuccess() : boolean {
        return this.m_bForgotSuccess;
    }

    constructor(private m_oJitsiSVC: ConferenceService,
                private m_oRoute: ActivatedRoute,
                private m_oRouter: Router,
                private m_oHttp: HttpClient,
                private m_oCfgSvc: ConfigService) { }

    ngOnInit(): void {
        let oDCfg = this.m_oCfgSvc.domainConfig();
        
        if (oDCfg) {
            this.m_sLogo   = oDCfg.customLogo ? oDCfg.customLogo : this.m_sLogo;
            this.m_bHeader = typeof (oDCfg.showHeader) === 'undefined' 
                             ? this.m_bHeader : oDCfg.showHeader;
            this.m_sCustomCSS = oDCfg.customeCssClass ? oDCfg.customeCssClass :
                                this.m_sCustomCSS;
            this.m_sHeaderContent = oDCfg.headerContent ? oDCfg.headerContent :
                                  this.m_sHeaderContent;
        }
        
        if (!environment.production) {
            let sLogin = localStorage.getItem("login")

            if (sLogin) {
                setTimeout(() => {
                    let aLogin = sLogin.split(";")
                    this.m_sLogin = aLogin[0];
                    this.m_sPasswd = aLogin[1];
                    this.onSubmit();
                }, 5000);
            }
        }
    }
    
    private resetPassword(p_sUser : string) {
        
        return this.m_oHttp.post("/api/reset-password", {
            username: p_sUser,
            host: this.m_oCfgSvc.getXMPPAuthDomain(),
            wwwhost: ConfigService.hostName()
        });
    }
    
    private genXmppDomain(p_sDomainID : string) {
        if (p_sDomainID)
            return p_sDomainID + "." + environment.baseDomain;
            
        return environment.xmppAuthDomain;
    }
    
    private genAuth(p_sLogin : string, p_sDomain : string) : string {
        return p_sLogin + "@" + p_sDomain + "/" 
               + environment.xmppAuthResource;
    }
        
    public onSubmit() {
        this.m_bError = false;
        
        let sDomainID = this.m_oRoute.snapshot.paramMap.get("c_id");
        
        // this.m_oJitsiSVC.connect(this.genAuth(this.m_sLogin, this.genXmppDomain(sDomainID)), this.m_sPasswd, this.genXmppDomain(sDomainID))
        this.m_oJitsiSVC.connect(this.m_sLogin, this.m_sPasswd)
            .subscribe({next : p_oResult => {
                let sReturnUrl = this.m_oRoute.snapshot.queryParams['returnUrl'] || '/ucebna/';
                this.m_oRouter.navigate([sReturnUrl]);
                console.log("login connect ok");
                
                if (!environment.production)
                    localStorage.setItem("login", this.m_sLogin + ';' + this.m_sPasswd);
                    
            }, error: p_oError => {
                this.m_bError = true;
                console.error("login connect fail", p_oError);                
            }});
        console.log("submit");
        return false;
    }
    
    public onForgotSubmit() {
        if (this.m_bProgress) return;
        
        let oThis = this;
        let bLoad = true;
        let bError = false;
        let bTimer = true;
        this.m_bProgress = true;
        this.m_bDisabled = true;
        
        let mTId = setTimeout(function(){
            bTimer = false;
            if (!bLoad) {
                oThis.m_bProgress = false;
                oThis.m_bForgotSuccess = true;
            }
            // if (bError) oThis.m_bDisabled = false;
            
        }, 10000);
        
        this.resetPassword(this.m_sLogin).subscribe(p_oResult => {
            bLoad = false;
            if (!bTimer) {
                this.m_bProgress = false;
                this.m_bForgotSuccess = true;
            }
        }, p_oError => {
            bLoad = false;
            bError = true;
            
            if (!bTimer) {
                this.m_bProgress = false;
                this.m_bForgotSuccess = true;
                // this.m_bDisabled = false;           
            }
        });
        // this.m_bForgotPassword = false;
    }
}