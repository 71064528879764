import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'jitsi-remote-track',
  templateUrl: './jrtrack.component.html',
  styleUrls: ['./jrtrack.component.css']
})
export class JRTrackComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
