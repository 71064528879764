import {Injectable, TemplateRef } from '@angular/core';

export class CToastOptions {
    public delay?    : number = 35000;
    public clasName? : string = "";
    public header?   : string | TemplateRef<any>;
    
    constructor() {
        this.delay = 65000;
    }
}

export class CToast {
    public get delay() : number {
        if (this.m_oOptions)
            return this.m_oOptions.delay;
            
        return 0;
    }
    
    public get autoHide() : boolean {
        if (this.m_oOptions)
            return this.m_oOptions.delay > 0;
            
        return false;
    }
    
    public get className() : string {
        if (this.m_oOptions && this.m_oOptions.clasName)
            return this.m_oOptions.clasName;
         
        return "";
    }
    
    public set className(p_sCName : string) {
        if (this.m_oOptions)
            this.m_oOptions.clasName = p_sCName;
    }
    
    public get content(): string | TemplateRef<any> {
        return this.m_oTextOrTpl;
    }
    
    public get isTemplate() : boolean {
        return this.m_oTextOrTpl instanceof TemplateRef;
    }
    
    public get isHeader() : boolean {
        //console.log("toast service", this.m_oOptions && this.m_oOptions.header);
        if (this.m_oOptions && this.m_oOptions.header) {
            //console.log("toast service", this.m_oOptions && this.m_oOptions.header);
            return true;
        }
            
        return false;
    }
    
    public get isHeaderTemplate() : boolean {
        if (this.m_oOptions && this.m_oOptions.header) {
            //console.log("toast service htpl", this.m_oOptions.header instanceof TemplateRef);
            return this.m_oOptions.header instanceof TemplateRef;
        }
            
        return false;
    }
    
    public get headerText() : string {
        if (this.m_oOptions && !this.isHeaderTemplate)
            return this.m_oOptions.header as string;
        return undefined;
    }
    
    public get headerContent(): string | TemplateRef<any> {
        if (this.m_oOptions && this.m_oOptions.header)
            return this.m_oOptions.header;
            
        return "";
    }
    
    constructor(private m_oTextOrTpl: string | TemplateRef<any>, 
                private m_oOptions: CToastOptions = new CToastOptions()) { 
                
        if (this.m_oOptions && typeof (this.m_oOptions.delay) !== "number") {
            this.m_oOptions.delay = 5000;
        }
    }
}

export enum EColors {
    cInfo,
    cWarning,
    cError
}

type Alerts = 'info' | 'warning' | 'error';

type ICKeyValue = {
    [index in Alerts] : string;
}

@Injectable({
    providedIn: 'root'
})
export class AlertsService {
    private m_nMaxAlersts : number = 5;
    private m_aToasts: CToast[] = [];

    private m_sColorClass: ICKeyValue = {
                                            info: 'bg-info', 
                                            warning: "bg-warning", 
                                            error:'bg-danger'
                                        };
    private m_sWarningClass : string = "bg-warning";
    private m_sErrorClass   : string = "bg-danger";

    public get toasts(): CToast[] {
        return this.m_aToasts;
    }
    
    public get maxAlerts() : number {
        return this.m_nMaxAlersts;
    }
    
    public set maxAlerts(p_nMaxAlerts : number) {
        this.m_nMaxAlersts = p_nMaxAlerts;
    }

    constructor() { }
    
    public clearAlerts() {
        this.m_aToasts = [];
    }
    
    public setColor(p_tType: Alerts, p_sColor : string) {
        this.m_sColorClass[p_tType] = p_sColor;
    }
    
    private add(p_oToast: CToast) {
        if (this.m_aToasts.length >= this.m_nMaxAlersts) {
            this.m_aToasts.shift();
        }
        
        this.m_aToasts.push(p_oToast);
    }
    
    public remove(p_oToast: CToast) {
        let nIndex = this.m_aToasts.indexOf(p_oToast);
        
        if (nIndex >= 0)
            this.m_aToasts.splice(nIndex, 1);
    }
    
    public showInfo(p_oToast: CToast) {
        p_oToast.className += this.m_sColorClass['info'];
        this.add(p_oToast);
    };
    
    public showDanger(p_oToast: CToast) {
        p_oToast.className += this.m_sColorClass['error'];
        this.add(p_oToast);
    }
    
    public showWarning(p_oToast: CToast) {
        p_oToast.className += this.m_sColorClass['warning'];
        this.add(p_oToast);        
    }
}
