export class Track {
    private m_oVideoTrack: any;
    private m_oAudioTrack: any;
    
    public get video() : any {
        return this.m_oVideoTrack;
    }
    
    public get audio() : any {
        return this.m_oAudioTrack;
    }
    
    public get isEmpty() : boolean {
        return !(this.m_oVideoTrack || this.m_oAudioTrack);
    }
    
    public addTrack(p_oTrack : any) : any {
        console.log("[myJitsi][track] add", p_oTrack);
        if (p_oTrack.getType() == 'video') {
            let oPrevTrack = this.m_oVideoTrack;
            this.m_oVideoTrack = p_oTrack;
            return oPrevTrack;
        } else if (p_oTrack.getType() == 'audio') {
            let oPrevTrack = this.m_oAudioTrack;
            this.m_oAudioTrack = p_oTrack;
            return oPrevTrack;
        }
    }
    
    public removeTrack(p_oTrack : any) : any {
        if (p_oTrack.getType() == 'video') {
            let oPrevTrack = this.m_oVideoTrack;
            this.m_oVideoTrack = null;
            
            return oPrevTrack;
        } else if (p_oTrack.getType() == 'audio') {
            let oPrevTrack = this.m_oAudioTrack;
            this.m_oAudioTrack = p_oTrack;
            
            return oPrevTrack;
        }
    }
    
    public dispose() {
        if (this.m_oVideoTrack) this.m_oVideoTrack.dispose();
        if (this.m_oAudioTrack) this.m_oAudioTrack.dispose();
    }
}
