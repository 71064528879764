import {Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {FileItem, ChatService} from '../../chat.service';

@Component({
  selector: '[vClassFileItem], vclass-file-item',
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.component.css']
})
export class FileItemComponent implements OnInit {
    @Input("data")
    private m_oData: FileItem;
    
    @Output("onDelete")
    private m_oOnDelete: EventEmitter<FileItem> = new EventEmitter<FileItem>();
    
    get data(): FileItem {
        return this.m_oData;
    }    

    get editMode() : boolean {
        return !this.m_oData.uploaded && !this.m_oData.uploading;
    }
    
    get showSend() : boolean {
        return !this.m_oData.uploading && this.m_oData.uploaded;
    }

    get showProgressBar(): boolean {
        return this.m_oData.uploading && !this.m_oData.uploaded;
    }
    
    get stripe() : boolean {
        return this.m_oData.uploading;
    }

    constructor(private m_oChatSvc: ChatService) { }

    ngOnInit(): void { }

    public onDelete() {
        this.m_oOnDelete.emit(this.m_oData);
    }
    
    public onSend() {        
        this.m_oChatSvc.sendMsg(this.m_oData.toMessage());
    }
}
