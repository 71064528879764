import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ConferenceService } from './jitsi/conference.service';
import {ConfigService} from '../environments/config.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
    
    constructor(private m_oJitsiSVC: ConferenceService, 
                private m_oRouter: Router,
                private m_oCfgSvc: ConfigService) {
        
    }
    
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        
        if (this.m_oJitsiSVC.isConnected)
            return true;
        
        // this.m_oRouter.navigate(['login'], { queryParams: { returnUrl: state.url} });
        this.m_oRouter.navigate(this.genNavigation(route), 
                                { queryParams: { returnUrl: state.url} });
        return false;
        //    return true;
    }
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }
    canDeactivate(
        component: unknown,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }
    canLoad(
        route: Route,
        segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }
    
    private genNavigation(p_oRoute: ActivatedRouteSnapshot) : string[] {
        let aRoutes = [];
        let sCID = p_oRoute.paramMap.get("c_id");
        
        if (sCID && p_oRoute.data && p_oRoute.data['prefixed']) {
            // aRoutes.push("conference");
            // aRoutes.push(sCID);            
            this.m_oCfgSvc.prefix(sCID);
        }
        
        aRoutes.push("login");
        
        return aRoutes;
    }
}
