
import {KeyValueList} from './user-list';

export class JitsiLocalUser {
    private m_sDisplayName : string = "";
    private m_oProperties: KeyValueList<any> = {};
    
    constructor(private m_oConference : any) { }
    
    public getId() : string {
        if (this.m_oConference)
            return this.m_oConference.myUserId();
    }
    
    public getDisplayName() : string {
        return this.m_sDisplayName;
    }
    
    public setDisplyName(p_sName : string) {
        this.m_sDisplayName = p_sName;
        this.m_oConference.setDisplayName(p_sName);
    }
    
    public isModerator() : boolean {
        return this.m_oConference.isModerator();
    }
    
    public removeTrack(p_oTrack:any) {
        if (!p_oTrack) return;
        this.m_oConference.removeTrack(p_oTrack);
    }
    
    public replaceTrack(p_oTrackOld:any, p_oTrackNew: any) {
        if (!p_oTrackOld || !p_oTrackNew) return;
        return this.m_oConference.replaceTrack(p_oTrackOld, p_oTrackNew);
    }
    
    public addTrack(p_oTrack : any) {
        if (!p_oTrack) return;
        this.m_oConference.addTrack(p_oTrack);
    }
    
    public getProperty(p_sProperty : string) {
        return this.m_oProperties[p_sProperty];
    }
    
    public setProperty(p_sProperty : string, p_oVal : any) {
        this.m_oProperties[p_sProperty] = p_oVal;
        this.m_oConference.setLocalParticipantProperty(p_sProperty, p_oVal);
    }
}
