import {IDictionary} from '../idictionary';

export enum DomainMapping {
    dmPrefix,
    dmOneToOne
}

export class DomainConfig {
    isAlias         : boolean;
    aliasDomain?    : string;
    customLogo?     : string;
    domainMapping?  : DomainMapping;
    domainPrefix?   : string;
    showHeader?     : boolean;
    headerContent?        : string;
    customeCssClass?: string;
}

export type DomainMap = IDictionary<DomainConfig>;