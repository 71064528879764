import {Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, SecurityContext } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ChatItem} from '../../chat.service';

@Component({
  selector: '[vClassChatItem], vclass-chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.css']
})
export class ChatItemComponent implements OnInit, AfterViewInit {
    @ViewChild("message")
    private m_oElem: ElementRef<HTMLDivElement>;
    
    @Input("message")
    private m_oData: ChatItem;
    
    get data(): ChatItem {
        return this.m_oData;
    }
    
    get timeFormat() : string {
        return 'HH:mm:ss';
    }
    
    get userTypeIcon() : string {
        if (this.m_oData.user)
            return this.m_oData.user.isModerator ? "user-shield" : "user-graduate";
        return "user";
    }
    
    constructor(private m_oSanitizer: DomSanitizer) { }

    ngOnInit(): void { }

    ngAfterViewInit() {
        if (!this.m_oElem || !this.m_oElem.nativeElement) return;
        
        this.m_oElem.nativeElement.innerHTML = this.m_oSanitizer.sanitize(SecurityContext.HTML, this.m_oData.message);
        // console.info("MyJitsi html content", this.m_oElem.nativeElement.innerHTML);
    }
}
