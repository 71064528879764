import {Component, OnInit, Input } from '@angular/core';
import {UserList} from '../class/user-list';
import {User} from '../class/user';

@Component({
  selector: '[vClassLeftPanelUsers], vclass-left-panel-users',
  templateUrl: './left-panel-users.component.html',
  styleUrls: ['./left-panel-users.component.css']
})
export class LeftPanelUsersComponent implements OnInit {
    @Input("usersList")
    private m_oUserList: UserList;
    private m_bShow : boolean = true;
    
    public get remoteUsers() : string[] {
        if (this.m_oUserList)
            return this.m_oUserList.remoteUsers;
        
        return [];
    }
    
    public get localUser() : User {
        if (this.m_oUserList)
            return this.m_oUserList.localUser;
            
        return null;
    }
    
    public get hasList() : boolean {
        return this.m_oUserList ? true : false;
    }

    public get chevronType() : string {
        return this.m_bShow ? 'chevron-right' : 'chevron-left';
    }

    public get isShow() : boolean {
        return this.m_bShow;
    }
    

    constructor() { }

    ngOnInit(): void { }
    
    public onSelect(p_oUser : User) {
        this.m_oUserList.selectUser(p_oUser.id);
    }
    
    public user(p_sUserID : string) : User {
        return this.m_oUserList.get(p_sUserID);
    }
    
    public isSelected(p_mSelected : User | string) : boolean {
        if (!this.hasList || !this.m_oUserList.selectedUser)return false;
        
        if (p_mSelected instanceof User)
            return this.m_oUserList.selectedUser === p_mSelected;
            
        return this.m_oUserList.selectedUser.id === p_mSelected;
    }
    
    public toggle() {
        this.m_bShow = !this.m_bShow;
    }
}
