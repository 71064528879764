import {Component, OnInit, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
// import {ChatItemComponent} from './chat-item/chat-item.component';
import {ChatService, ChatItem} from '../chat.service';

@Component({
  selector: '[vClassChat], vclass-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, AfterViewInit {
    private m_sMessage : string = "";
    private m_nItemCount : number = 0;
    private m_bAllowScrollBtm : boolean = true;
    private m_bScrollTo : boolean = true;

    get messages(): ChatItem[] {
        this.scrollToBottom();
        return this.m_oChatSvc.messages;
    }
    
    get message() : string {
        return this.m_sMessage;
    }
    
    set message(p_sMsg : string) {
        this.m_sMessage = p_sMsg;
    }
    
    @ViewChild("scrollContainer")
    private m_oScollContainer: ElementRef<HTMLElement>;
    
    constructor(private m_oChatSvc: ChatService) { }

    ngOnInit(): void { this.scrollToBottom(); }
    
    ngAfterViewInit() {
    //    this.scrollToBottom();        
    }
    
    public send() {
        this.m_oChatSvc.sendMsg(this.m_sMessage);
        this.m_sMessage = "";
    }
    
    private scrollToBottom() {
        if (this.m_oScollContainer && this.m_oScollContainer.nativeElement) {
            let oElem = this.m_oScollContainer.nativeElement;
            
            if (this.m_bScrollTo) {
                this.m_oScollContainer.nativeElement.scrollTop = 
                this.m_oScollContainer.nativeElement.scrollHeight - 
                this.m_oScollContainer.nativeElement.clientHeight;
                
                this.m_bScrollTo = false;
            }
            // if ()
            this.m_bAllowScrollBtm = (oElem.scrollHeight - oElem.clientHeight 
                                      <= oElem.scrollTop + 10);
                
            if (this.m_bAllowScrollBtm && 
                this.m_nItemCount < this.m_oChatSvc.messages.length) {
                this.m_bScrollTo = true;
                this.m_bAllowScrollBtm = false;
            }
                
            this.m_nItemCount = this.m_oChatSvc.messages.length;
        }
    }
}
