// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {DomainMapping, DomainMap} from  "./domain-config";

export const environment = {
    domainConfig : {
        'skvza.virtualnaucebna.sk' : {
            isAlias : false,
            customLogo : 'skvza',
            domainMapping: DomainMapping.dmOneToOne,
            showHeader : false
        },
        'meeting.euroin.sk' : {
            isAlias : false,
            customLogo : 'euroin',
            domainMapping: DomainMapping.dmOneToOne,
            showHeader : true,
            headerContent: "Online konferencie",
            customeCssClass : "euroin"
        },
        'autoskola.virtualnaucebna.sk' : {
            isAlias : false,
            domainMapping: DomainMapping.dmPrefix,
            domainPrefix : 'as'
        },
        'jtest.virtualnaucebna.sk' : {
            isAlias : true,
            aliasDomain: 'tssgroup.virtualnaucebna.sk',
            domainMapping: DomainMapping.dmPrefix,
            domainPrefix : 'as'
            
        }
    } as DomainMap,
    
    production : false,
    xmppAuthResource : "tss_jiscd",
    xmppAuthDomain   : "skvza.virtualnaucebna.sk",
    tssfocus: "tssfocus.internal.virtualnaucebna.sk",
    baseDomain: "virtualnaucebna.sk",
    
    chat : {
        maxShowItems : 300
    },
        
    jitsi : {
        initOptions : {
            disableAudioLevels : false,
            // preferredCodec: 'H264',
            // preferredCodec: 'VP9',
            // disableRTX : false,
        },
        confOptions : {
            openBridgeChannel: true,
            rttMonitor : {
                enabled : true,
                initialDelay: 5000,
                getStatsInterval : 10000,
                analyticsInterval: 5000
            },
            e2eping : {
                pingInterval : -1
            }
        },
        connOptions : {
            hosts : {
                domain : "skvza.virtualnaucebna.sk",
                muc    : "conference.", //skvza.virtualnaucebna.sk", // FIXME: use XEP-0030
                focus  : "focus.internal.virtualnaucebna.sk",
            },
            clientNode: 'http://jitsi.org/jitsimeet',
            xmppPing : {
                timeout: 20000,
                treshold: 10
            },
            disableSimulcast: false,
           // enableRemb: false,
           // enableTcc: true,
           // resolution: 720,
           /* constraints: {
                video: {
                    aspectRatio: 16 / 9,
                    height: {
                        ideal: 720,
                        max: 720,
                        min: 180,
                    },
                    width: {
                        ideal: 1280,
                        max: 1280,
                        min: 320,
                    },
                },
            },*/
            // serviceUrl: "ws://10.100.2.64:5280/ws",
            serviceUrl: "wss://tssgroup.virtualnaucebna.sk/xmpp/ws",
            //serviceUrl: "wss://skvza.virtualnaucebna.sk/xmpp/ws",
            //externalConnectUrl: "//beta.meet.jit.si/http-pre-bind",
            //p2pStunServers: [            
            //    { urls: "stun:10.100.2.64:3478" },
            //    { urls: "stun:10.100.2.64:5349" }                
            /*  { urls: "stun:stun.l.google.com:19302" },
              { urls: "stun:stun1.l.google.com:19302" },
              { urls: "stun:stun2.l.google.com:19302" },*/
            //],
            enableP2P: false, // flag to control P2P connections
            p2p: {
                enabled: false,
               // preferH264: true,
               // disableH264: true,
            //    useStunTurn: true, // use XEP-0215 to fetch STUN and TURN server for the P2P connection
            //    stunServers: [
            //    { urls: "stun:10.100.2.64:3478" },
            //    { urls: "stun:10.100.2.64:5349" }
                /*  { urls: "stun:stun.l.google.com:19302" },
                  { urls: "stun:stun1.l.google.com:19302" },
                  { urls: "stun:stun2.l.google.com:19302" },*/
            //    ],
            },
            useStunTurn: false, // use XEP-0215 to fetch STUN and TURN server for the JVB connection
            //useIPv6: false, // ipv6 support. use at your own risk
            //useNicks: false,
            // bosh: "https://beta.meet.jit.si/http-bind", // FIXME: use xep-0156 for that
            //openBridgeChannel: "websocket", // One of true, 'datachannel', or 'websocket'
            //channelLastN: -1, // The default value of the channel attribute last-n.
            //minHDHeight: 540,
            //startBitrate: "800",
            //useRtcpMux: true,
            //useBundle: true,
            //disableSuspendVideo: true,
            //stereo: false,
            //forceJVB121Ratio: -1,
            //enableTalkWhileMuted: true,
            //enableClosePage: true,
        }
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
