// import {EventEmitter} from '@angular/core';
import {Observable, Observer, BehaviorSubject } from 'rxjs';
import {User} from './user';

export interface KeyValueList<V> {
    [p_sKey : string] : V;
}

export class UserList {
//    private m_oOnSelectUser: EventEmitter<User> = new EventEmitter<User>();
    private m_oOnSelectUser = new BehaviorSubject<User>(null);   // Observer<User>; // = new EventEmitter<User>();
    // private m_oOnSelectUserObservable: new Observable<User>(p_oObServer =>  ); // = new EventEmitter<User>();
    private m_sLocalUserID: string;
    private m_sDominantSpeakerID: string = "";
    private m_oRegister: KeyValueList<User> = { };
    private m_aRemoteUsers : string[] = [];
    private m_aTotalUsers : string[] = [];
    private m_sSelectedUser : string;
    
    public get dominantSpeakerID() : string {
        return this.m_sDominantSpeakerID;
    }
    
    public set dominantSpeakerID(p_sUID : string) {
        if (this.m_sDominantSpeakerID.length > 0 && 
            this.m_sDominantSpeakerID != p_sUID) {
            let oUser = this.get(this.m_sDominantSpeakerID);
            if (oUser)
                oUser.isSpeaker = false;
        }
        
        this.m_sDominantSpeakerID = p_sUID;
        
        let oUser = this.get(this.m_sDominantSpeakerID);
        if (oUser)
            oUser.isSpeaker = true;
    }
    
    public get selectedUser() : User {
        if (this.m_sSelectedUser)
            return this.get(this.m_sSelectedUser);
        
        return this.localUser;
    }
    
    public get localUser() : User {
        if (!this.m_sLocalUserID) return null;
        
        let oUser = this.m_oRegister[this.m_sLocalUserID];
        
        return oUser;            
    }
    
    public get totalUsers() : string[] {
        return this.m_aTotalUsers;
    }
    
    public get remoteUsers() : string[] {
        return this.m_aRemoteUsers;
    }
    
    private genKey(p_sUserID : string) : string {
        return 'U_' + p_sUserID;
    }
    
    public addEvent(p_oEvent : (p_oUser:User)=>void) {
        this.m_oOnSelectUser.asObservable().subscribe(p_oEvent);
    }
    
    public add(p_oUser: User): User {
        if (!p_oUser) return null;
        
        let sKey = this.genKey(p_oUser.id);
        
        if (!this.m_oRegister[sKey]) {
            this.m_oRegister[sKey] = p_oUser;
            
            if (!p_oUser.isLocal) {
                this.m_aTotalUsers.push(p_oUser.id);
                this.m_aRemoteUsers.push(p_oUser.id);
            } else {
                this.m_aTotalUsers.unshift(p_oUser.id);
                this.m_sLocalUserID = sKey;
            }
            
            return p_oUser;
        }
        
        return null;
    }
    
    public remove(p_sUserID : string) : User {
        let sKey = this.genKey(p_sUserID);
        
        if (this.m_oRegister[sKey]) {
            let oUser = this.m_oRegister[sKey];
            delete this.m_oRegister[sKey];
            
            let nAllIndex = this.m_aTotalUsers.indexOf(p_sUserID);
            this.m_aTotalUsers.splice(nAllIndex, 1);
            
            console.log("[myJitsi] remove user", p_sUserID, nAllIndex, this.m_aTotalUsers);
                        
            if (!oUser.isLocal) { 
                let nLIndex = this.m_aRemoteUsers.indexOf(p_sUserID);
                this.m_aRemoteUsers.splice(nLIndex, 1);
                console.log("[myJitsi] remove remote user", nLIndex, this.m_aRemoteUsers);
            } else {
                this.m_sLocalUserID = null;
            }
            
            return oUser;         
        }
        
        return null;
    }
    
    public get(p_sUserID : string) : User {
        let sKey = this.genKey(p_sUserID);
        
        //console.log("[myJitsi] UserList get", sKey, this.m_oRegister[sKey]);
        
        if (this.m_oRegister[sKey])
            return this.m_oRegister[sKey];
            
        return null;
    }
    
    public selectUser(p_sUserID : string) {
        console.log("[myJitsi] selectUser", p_sUserID);
        let oUser = this.get(p_sUserID);
        
        if (oUser) {
            this.m_sSelectedUser = p_sUserID;
            this.m_oOnSelectUser.next(oUser);
        }
        return oUser;
    }
}
