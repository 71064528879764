import { Injectable } from '@angular/core';
import {environment} from './environment';
import {DomainMapping, DomainConfig} from './domain-config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
    private m_sDomainPrefixID : string = '';
    
    constructor() { }
    
    public static hostName() : string {
        if (!environment.production)
            return 'tssgroup.virtualnaucebna.sk';
            // return 'meeting.euroin.sk';
            
        return window.location.hostname;
    }
    
    public static hasPrefix() : boolean {
        let oDomain = environment.domainConfig[ConfigService.hostName()];
        
        if (oDomain) 
            return oDomain.domainMapping === DomainMapping.dmPrefix;
        
        return false;
    }
    
    public domainConfig() : DomainConfig {
        return environment.domainConfig[ConfigService.hostName()];
    }
    
    public prefix(p_sPrefix : string) : string {
        if (typeof (p_sPrefix) === 'string')
            this.m_sDomainPrefixID = p_sPrefix;
            
        return this.m_sDomainPrefixID;
    }
    
    public getXMPPAuthDomain() : string {
        let oDomainConfig = environment.domainConfig[ConfigService.hostName()];
        
        if (ConfigService.hasPrefix()) {
            if (oDomainConfig) 
                return oDomainConfig.domainPrefix + this.m_sDomainPrefixID 
                       + '.' + environment.baseDomain;
        }        
        
        return ConfigService.hostName();
    }
    
    public getXMPPServiceUrl() : string {
        
        if (!environment.production)
            return environment.jitsi.connOptions.serviceUrl;
            
        return 'wss://' + ConfigService.hostName()
                + environment.jitsi.connOptions.serviceUrl;
    }
    
    public getXMPPMuc() : string {
        return environment.jitsi.connOptions.hosts.muc 
               + this.getXMPPAuthDomain();
    }
    
    public genXMPPLoginJID(p_sLogin : string) : string {
        return p_sLogin + '@' + this.getXMPPAuthDomain() + '/' 
               + environment.xmppAuthResource;
    }
}
