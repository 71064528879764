import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {ConferenceComponent} from './jitsi/conference/conference.component';
import {LoginComponent} from './login/login.component';
import { AuthGuard } from './auth.guard';
import { ConfigService } from '../environments/config.service';

const prefixedRoutes: Routes = [
    // base route redirect to ucebna
    {   path: '',   redirectTo: 'login', pathMatch:'full'  },
    // route login
    {   
        path: 'login', 
        component: LoginComponent
    },
    // ucebna route without params redirect to login
    {   
        path: 'ucebna', 
        redirectTo: 'login'
        // component: ConferenceComponent, 
        //canActivate: [AuthGuard] 
    },
    // route ucebna with customer
    {   
        path: 'ucebna/:c_id', 
        data : { 
            'prefixed' : true
        }, 
        children: [
            // route without room id
            {
                path: '', 
                redirectTo: '/login', 
                pathMatch: 'full',
            },
            // route with room id
            {
                path: ':room_id',
                component: ConferenceComponent,
                canActivate: [AuthGuard],
            }
        ]
    },
    // route room with customer id
    {   path: 'room/:c_id', 
        data : {
                'prefixed' : true
        }, 
        children: [
            {
               path: '', 
               redirectTo: '/login', 
               pathMatch: 'full'
            },
            {
                path: ':room_id',
                component: ConferenceComponent, 
                canActivate: [AuthGuard],
            }
        ]
    },
    
    // conference route with customer id
    {   path: 'conference/:c_id', 
        data : {
            'prefixed':true
        }, 
        children: [
            {
               path: '', 
               redirectTo: '/login', 
               pathMatch: 'full'
            },
            
            {
                path: ':room_id',
                component: ConferenceComponent, 
                canActivate: [AuthGuard],
            } 
        ]
    },
    // 404 route redirect to ucebna
    {   path: '**', redirectTo: '/login', pathMatch:'full'  }
];

const oneToOneRoutes: Routes = [
    // base route redirect to ucebna
    {   
        path: '', 
        redirectTo: '/login', 
        pathMatch:'full'
    },    
    // login route
    {   
        path: 'login', 
        component: LoginComponent
    },
    // ucebna route with room id
    {   
        path: 'ucebna/:room_id', 
        component: ConferenceComponent, 
        canActivate: [AuthGuard]
    },
    // ucebna route without room id
    {   
        path: 'ucebna', 
        component: ConferenceComponent, 
        canActivate: [AuthGuard]
    },
    // { path: 'conference/:c_id/login', component: LoginComponent },
    // { path: 'conference', component: ConferenceComponent, canActivate: [AuthGuard] },
    // 404 route to ucebna redirect to ucebna
    {   
        path: '**', 
        redirectTo: '/login', 
        pathMatch:'full'
    }
    
];

@NgModule({
    imports: [RouterModule.forRoot(ConfigService.hasPrefix() ?
                    prefixedRoutes : oneToOneRoutes, {enableTracing : false})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
