import { Pipe, PipeTransform } from '@angular/core';
import {DecimalPipe} from '@angular/common/';

const sKB = 1024;
const sMB = 1024*1024;
const sGB = sMB*1024;

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

    transform(p_nValue: number, p_sDigit : string =  '1.2-2', p_sUnit: string = "auto"): string {
        let oDP = new DecimalPipe('en-US');
        
    //    console.log('fs', p_sDigit, p_sUnit);
        
        if ((p_nValue < sKB && p_sUnit === 'auto') || p_sUnit == "B")
            return p_nValue.toString() + " B";
        else if ((p_nValue >= sKB && p_nValue < sMB && p_sUnit === 'auto') || p_sUnit == "KB")
            return oDP.transform((p_nValue / sKB), p_sDigit).toString() + " KB";
        else if ((p_nValue >= sMB && p_nValue < sGB && p_sUnit === 'auto') || p_sUnit == "MB")
            return oDP.transform(p_nValue / sMB, p_sDigit) + " MB";
            
        return oDP.transform((p_nValue / sGB), p_sDigit) + " GB";
    }

}
